import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BlogDetail } from "../../DAL/Blog/Blog";
// import HTMLReactParser from "html-react-parser";
import { CardMedia, Skeleton } from "@mui/material";
import { picBaseUrl } from "../../config/config";
import { ShimmerDiv } from "shimmer-effects-react";
import moment from "moment";

const BolgDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const GetBlogDetailData = async () => {
    setLoading(true);
    const result = await BlogDetail(id);
    if (result?.status === true) {
      setData(result?.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetBlogDetailData();
  }, []);
  return (
    <div className="pb-5">
      <div
        className="home1 row  mt-4"
        style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}
      >
        <h2 className="text-center mb-3 text-warning">{data?.title}</h2>
        <p className="text-secondary text-center">
          By <span className="text-white">{"Admin"} </span> -{" "}
          {moment(data?.created_at).format("MMMM D, YYYY")}
        </p>
        {loading === true ? (
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={"100vh"}
            className="rounded shimmer"
          />
        ) : (
          <>
            <div className="col-lg-12">
              <CardMedia
                component="img"
                className="blog-detail-image"
                image={picBaseUrl + data?.thumbnail}
                alt="green iguana"
              />
            </div>
            <div className="container mt-3 text-white">
              <div className="row">
                <div className="col-lg-3">
                  <div className="left">
                    <div className="dummy-ad-vertical">
                      <p>Ad Space</p>
                      <p>160 x 600</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  {/* <div className="d-flex">
                      <h1>{data?.title}</h1>
                    </div> */}
                  <div className="text-white text-start container container-blog">
                    <div
                      dangerouslySetInnerHTML={{ __html: data?.description }}
                    ></div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="left">
                    <div className="dummy-ad-vertical">
                      <p>Ad Space</p>
                      <p>160 x 600</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BolgDetails;
