import { Box, Button, Slider } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RingSizeActivity = () => {
  const [diameterInPixels, setDiameterInPixels] = useState(100);
  const [ringSizes, setRingSizes] = useState({
    GB: "",
    BG: "",
    JP: "",
    Inches: "",
    mm: "",
  });
  const [isSizeSaved, setIsSizeSaved] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const savedSize = localStorage.getItem("is_size_save");
    if (savedSize) setIsSizeSaved(JSON.parse(savedSize));
  }, []);

  const handleFinishClick = () => {
    const calculatedSizes = getCalculatedRingSizes();
    if (Object.keys(calculatedSizes).length > 0) {
      localStorage.setItem("GB_SIZE", calculatedSizes.GB);
      navigate(-1);
    }
  };

  const handleCalculateClick = () => {
    const diameterInMm = pixelsToMm(diameterInPixels);
    const sizes = calculateRingSizes(diameterInMm);
    setRingSizes(sizes);
  };

  const pixelsToMm = (pixels) => {
    const dpi = window.devicePixelRatio * 96; // Approximation for DPI
    return (pixels / dpi) * 25.4;
  };

  const calculateRingSizes = (diameterInMm) => {
    const gbSize = (diameterInMm / 0.8128).toFixed(1);
    const bgSize = (diameterInMm / 0.789).toFixed(1);
    const jpSize = (diameterInMm / 0.7).toFixed(1);
    const inches = (diameterInMm / 25.4).toFixed(2);
    const mm = diameterInMm.toFixed(2);

    return {
      GB: gbSize,
      BG: bgSize,
      JP: jpSize,
      Inches: inches,
      mm: mm,
    };
  };

  const getCalculatedRingSizes = () => {
    const { GB, BG, JP, Inches, mm } = ringSizes;
    if (GB && BG && JP && Inches && mm) {
      return ringSizes;
    }
    return {};
  };

  return (
    <>
      <div className="mt-3 Ring-size m-auto">
        <div className="text-white Ring-size-content">
          <div
            style={{
              backgroundColor: "#0000004c",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              height: "40vh",
            }}
          >
            <div
              className="circle"
              style={{
                height: `${diameterInPixels}px`,
                width: `${diameterInPixels}px`,
                borderRadius: "50%",
                backgroundColor: "transparent",
                border: "3px solid #FFC107",
              }}
            ></div>
          </div>
          <h6 className="text-start mt-2">Adjust the Size:</h6>
          <div style={{ width: "80%", margin: "auto" }}>
            <Slider
              aria-label="Custom slider"
              min={10}
              max={200}
              value={diameterInPixels}
              onChange={(e) => setDiameterInPixels(Number(e.target.value))}
              sx={{
                height: 20,
                "& .MuiSlider-thumb": {
                  display: "none",
                },
                "& .MuiSlider-rail": {
                  backgroundColor: "white",
                  opacity: 1,
                  borderRadius: 1,
                },
                "& .MuiSlider-track": {
                  backgroundColor: "#FFC107",
                  border: "none",
                  borderRadius: 1,
                },
              }}
            />
          </div>
          <Button
            className="addtoCart text-dark"
            sx={{ textTransform: "none" }}
            onClick={handleCalculateClick}
          >
            Calculate
          </Button>
          <div className="text-start">
            <h6 className="mb-2">Result:</h6>
            <p style={{ display: "flex", justifyContent: "space-between" }}>
              {" "}
              <span>GB:</span> {ringSizes.GB}
            </p>{" "}
            <hr />
            <p style={{ display: "flex", justifyContent: "space-between" }}>
              <span>BG:</span> {ringSizes.BG}
            </p>{" "}
            <hr />
            <p style={{ display: "flex", justifyContent: "space-between" }}>
              <span>JP:</span> {ringSizes.JP}
            </p>{" "}
            <hr />
            <p style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Inches:</span> {ringSizes.Inches}
            </p>{" "}
            <hr />
            <p style={{ display: "flex", justifyContent: "space-between" }}>
              <span>mm:</span> {ringSizes.mm}
            </p>
          </div>

          <div className="text-center">
            <Button
              className="addtoCart text-dark"
              sx={{ textTransform: "none" }}
              onClick={handleFinishClick}
            >
              Finish
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RingSizeActivity;
