import {
  Card,
  CardContent,
  CardMedia,
  Pagination,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { picBaseUrl } from "../../config/config";

export default function CategoriesListing() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [page, setPage] = useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <div className="mainDiv">
        <div
          style={{
            width: "95%",
            margin: "1.5rem auto 0px auto",
          }}
        ></div>
        <div style={{ width: "93%", margin: "auto" }}>
          <div className="row">
            <Typography variant="h6" className="text-white ">
              All Categories
            </Typography>
            {state?.map((val) => {
              return (
                <div className="col-lg-3 mt-4">
                  <Card
                    className="card cardClassCategory h-100"
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(`/category-products/${val?.id}`, {
                        state: val?.title,
                      })
                    }
                  >
                    <CardMedia
                      sx={{ height: 175, width: "100%", cursor: "pointer" }}
                      image={picBaseUrl + val?.thumbnail}
                      alt=""
                    />
                    <CardContent className="cardContent text-center">
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        className="text-warning"
                      >
                        {val?.title}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
