import React, { useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { IoBagRemove } from "react-icons/io5";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { BsShare } from "react-icons/bs";
import { TiStarFullOutline } from "react-icons/ti";
import DescriptionSection from "./DescriptionSection";
import { styled } from "@mui/material/styles";
import RecommendedProducts from "./RecommendedProducts";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ImageSlider from "./ImageSlider";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import {
  AddToCart,
  FavoriteUnFavorite,
  ProductDetail,
} from "../../DAL/Products/Products";
import { enqueueSnackbar } from "notistack";
import { useScroll } from "../../hooks/AppContext";
import { picBaseUrl } from "../../config/config";
import { FaLocationDot } from "react-icons/fa6";

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(1, 3.5),
}));

export default function DetailSection() {
  const navigate = useNavigate();
  const { GetCartQuantity, NoToken, handleScrollToTop } = useScroll();
  const { id } = useParams();
  const [data, setData] = useState();
  const [sizes, setSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value < 0 ? 0 : event.target.value);
  };
  const handleSizeClick = (size) => {
    setSelectedSize(size);
  };

  const GetDetailProduct = async () => {
    const resp = await ProductDetail(id);
    if (resp?.status == true) {
      const savedSize = localStorage.getItem("GB_SIZE");
      setRecommendedProducts(resp?.recommended);
      let newSizes = JSON.parse(resp?.data?.sizes)?.sort(
        (a, b) => Number(a) - Number(b)
      );
      setSelectedSize(!savedSize ? newSizes[0] : savedSize);
      setSizes(newSizes);
      setData(resp?.data);
      setLoading(false);
    }
  };

  const handleAddToCart = async () => {
    if (!selectedSize) {
      enqueueSnackbar("Please enter a size", { variant: "error" });
      return;
    }
    setBtnLoading(true);
    const formData = new FormData();
    formData.append("product_id", id);
    formData.append("size", selectedSize);

    const resp = await AddToCart(formData);
    if (resp?.status === true) {
      setIsClicked(true);
      setTimeout(() => {
        setIsClicked(false);
      }, 3000);
      GetDetailProduct();
      setBtnLoading(false);
      enqueueSnackbar(resp?.message, { variant: "success" });
      localStorage.removeItem("GB_SIZE");
      GetCartQuantity();
      setSelectedSize("");
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  const handleFavorite = async (e) => {
    setIsChecked(e.target.checked);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("status", e.target.checked == true ? 1 : 0);
    const resp = await FavoriteUnFavorite(formData);
    if (resp?.status == true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      GetDetailProduct();
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data?.name) {
      document.title = data?.name;
    }
  }, [id, data]);

  useEffect(() => {
    NoToken();
    const savedSize = localStorage.getItem("GB_SIZE");
    if (savedSize) {
      setLoading(false);
    }
    GetDetailProduct();
  }, [id]);
  return (
    <>
      <div className="mt-4">
        <div className="row" style={{ margin: "auto", width: "95%" }}>
          <div className="col-lg-8">
            {loading == true && (
              <Skeleton
                sx={{
                  height: "400px",
                  width: "100%",
                }}
                animation="wave"
                variant="rectangular"
              />
            )}
            {loading == false && <ImageSlider data={data?.product_image} />}
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 ">
            {loading == true &&
              Array.from({ length: 7 }).map(() => (
                <>
                  <Skeleton
                    sx={{
                      height: 30,
                      width: "100%",
                      borderRadius: "5px",
                      marginTop: "1.5rem",
                    }}
                    animation="wave"
                    variant="rectangular"
                  />
                </>
              ))}
            {loading == false && (
              <>
                <Typography variant="h6" className="text-white">
                  {data?.name}
                </Typography>
                <div className="d-flex gap-5 align-items-center mt-2">
                  <span className="d-flex gap-2 mt-3">
                    <IoBagRemove style={{ fontSize: "25px", color: "gold" }} />
                    <p className="text-secondary mt-1">{data?.weight}</p>
                  </span>
                  <span className="d-flex gap-4 align-items-center">
                    <BsShare style={{ color: "white" }} />

                    <FormControlLabel
                      style={{ color: "white" }}
                      control={
                        <Checkbox
                          icon={<FavoriteBorder sx={{ color: "white" }} />}
                          checkedIcon={<Favorite />}
                          onChange={handleFavorite}
                          style={{ color: "gold" }}
                          checked={
                            data?.is_favourite == true || isChecked == true
                          }
                        />
                      }
                    />
                  </span>
                </div>
                <span className="d-flex gap-1">
                  <p className="text-secondary">
                    Rating: {Number(data?.rating)?.toFixed(1)}
                  </p>
                  <TiStarFullOutline
                    style={{ color: "gold", marginTop: "3px" }}
                  />
                </span>
                <Typography variant="h6" className="text-white">
                  Rs.{data?.price}
                </Typography>
                {sizes?.length > 0 && (
                  <>
                    <Typography className="text-white" variant="subtitle1">
                      Sizes
                    </Typography>
                    <span className="d-flex flex-wrap gap-2">
                      {sizes?.map((val, index) => (
                        <p
                          key={index}
                          style={{
                            border: `1px solid ${
                              selectedSize === val ? "#ffbf00" : "grey"
                            }`,
                            cursor: "pointer",
                            borderRadius: "3px",
                            padding: "5px 8px 2px 6px",
                            textAlign: "center",
                            color: selectedSize === val ? "#ffbf00" : "grey",
                          }}
                          onClick={() => handleSizeClick(val)}
                        >
                          {val}
                        </p>
                      ))}
                    </span>
                    <Typography variant="subtitle1" className="text-white">
                      Custom Size
                    </Typography>
                    <TextField
                      placeholder="Enter size "
                      size="small"
                      className="text-white"
                      onChange={handleSizeChange}
                      value={selectedSize}
                      type="number"
                      InputLabelProps={{
                        style: { color: "silver" },
                      }}
                      sx={{
                        input: { color: "white" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "silver",
                          },
                          "&:hover fieldset": {
                            borderColor: "silver",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "silver", // Border color on focus
                          },
                        },
                      }}
                    />{" "}
                    <br />
                    <Button
                      variant="outlined"
                      sx={{
                        border: "1px solid #ffc107",
                        color: "#ffc107",
                        textTransform: "none",
                        fontWeight: "bold",
                        mt: 2,
                        "&:hover": {
                          backgroundColor: "#ffc107",
                          color: "black",
                          border: "1px solid #ffc107",
                        },
                      }}
                      onClick={() => {
                        handleScrollToTop();
                        navigate("/RingSizeActivity");
                      }}
                    >
                      Measure Size
                    </Button>
                    <br />
                  </>
                )}

                {data?.is_cart == true ? (
                  <button
                    className={`cart-button`}
                    onClick={() => navigate("/cart")}
                  >
                    <span className="add-to-cart">Go to cart</span>
                  </button>
                ) : (
                  <button
                    disabled={btnLoading == true}
                    className={`cart-button ${isClicked ? "clicked" : ""}`}
                    onClick={handleAddToCart}
                  >
                    <span className="add-to-cart">Add to cart</span>
                    <span className="added">Added</span>
                    <ShoppingCartIcon className="fas fa-shopping-cart" />
                    <AccountBalanceWalletIcon className="fas fa-box" />
                  </button>
                )}
              </>
            )}
          </div>
          <div className="col-lg-8 col-sm-12  mt-5 productDetailLeftSide">
            {loading == true &&
              Array.from({ length: 5 }).map(() => (
                <>
                  <Skeleton
                    sx={{
                      height: 20,
                      width: "100%",
                      borderRadius: "5px",
                      marginTop: "1.5rem",
                    }}
                    animation="wave"
                    variant="rectangular"
                  />
                </>
              ))}
            {loading == false && (
              <DescriptionSection description={data?.description} />
            )}
          </div>
          {loading == true && (
            <div className="col-lg-4 mt-5">
              {Array.from({ length: 3 }).map(() => (
                <>
                  <Skeleton
                    sx={{
                      height: 20,
                      width: "100%",
                      borderRadius: "5px",
                      marginTop: "1.5rem",
                    }}
                    animation="wave"
                    variant="rectangular"
                  />
                </>
              ))}
            </div>
          )}

          {loading == false && (
            <div className="col-lg-4 ">
              <div className="d-flex justify-content-between mt-5">
                <div style={{ width: "80%" }}>
                  <Box sx={{ mb: 2, mx: -3 }}>
                    <AccountStyle>
                      <Avatar
                        src={picBaseUrl + data?.sellers?.shop_logo}
                        alt={""}
                        sx={{ width: 50, height: 50, cursor: "pointer" }}
                        onClick={() => {
                          handleScrollToTop();
                          navigate(`/seller-shop/${data?.sellers?.id}`);
                        }}
                      />

                      <Box sx={{ ml: 1 }}>
                        <Typography variant="subtitle1" className="text-white">
                          {data?.sellers?.shop_name}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className="text-secondary"
                        >
                          <FaLocationDot /> {data?.sellers?.shop_location}
                        </Typography>
                      </Box>
                    </AccountStyle>
                  </Box>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      handleScrollToTop();
                      navigate(`/seller-shop/${data?.sellers?.id}`);
                    }}
                    sx={{
                      textTransform: "none",
                      fontSize: {
                        lg: "14px",
                        md: "12px",
                        sm: "11px",
                        xs: "10px",
                      },
                      fontWeight: "bold",
                      backgroundColor: "gold",
                      color: "black",
                      marginTop: "15px",
                      "&:hover": {
                        backgroundColor: "gold", // background on hover
                      },
                    }}
                  >
                    Visit Shop
                  </Button>
                </div>
              </div>
              <div className="d-flex gap-3 mt-1 ">
                <Box sx={{ ml: { lg: 8, sm: 8, xs: 7 }, textAlign: "center" }}>
                  <Typography variant="subtitle1" className="text-white">
                    {Number(data?.sellers?.total_avg_rating)?.toFixed(1)}{" "}
                    <TiStarFullOutline
                      style={{ color: "gold", marginBottom: "5px" }}
                    />
                  </Typography>
                  <Typography variant="subtitle2" className="text-secondary">
                    Rating
                  </Typography>
                </Box>
                <Box sx={{ ml: 1, textAlign: "center" }}>
                  <Typography variant="subtitle1" className="text-white">
                    {data?.sellers?.total_followers}
                  </Typography>
                  <Typography variant="subtitle2" className="text-secondary">
                    Followers
                  </Typography>
                </Box>
                <Box sx={{ ml: 1, textAlign: "center" }}>
                  <Typography variant="subtitle1" className="text-white">
                    {data?.sellers?.products_count}
                  </Typography>
                  <Typography variant="subtitle2" className="text-secondary">
                    Products
                  </Typography>
                </Box>
              </div>
            </div>
          )}

          <hr style={{ color: "white", marginTop: "20px" }} />

          {recommendedProducts?.length > 0 && (
            <>
              <Typography variant="h5" className="text-white">
                Recommended Products
              </Typography>
              <div className="mt-3 ">
                <RecommendedProducts recommended={recommendedProducts} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
