import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import { LuMapPin } from "react-icons/lu";
import { FaStar } from "react-icons/fa";
import { Pagination, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  GetSellerFollowedShops,
  ShopFollowUnFollow,
} from "../../DAL/SellerShop/SellerShop";
import { picBaseUrl } from "../../config/config";
import { useSnackbar } from "notistack";
import { useScroll } from "../../hooks/AppContext";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});
export default function FollowedShops() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { handleScrollToTop } = useScroll();

  const GetFollowedShops = async () => {
    setLoading(true);
    const resp = await GetSellerFollowedShops();
    if (resp.status == true) {
      setData(resp?.data);
      setLoading(false);
    }
  };

  const handleToggleFollow = async (id, val) => {
    setValue(false);
    const data = {
      id: id,
      status: val,
    };
    const resp = await ShopFollowUnFollow(data);
    if (resp?.status == true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      GetFollowedShops();
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };
  useEffect(() => {
    GetFollowedShops();
  }, []);
  return (
    <>
      <div className="mt-3">
        <div style={{ width: "96%", margin: "auto" }}>
          <Typography variant="h5" className="text-white mb-4">
            Followed Shops
          </Typography>
          {loading == false && data.length == 0 && (
            <h4 className="text-center text-white mt-5 mb-5">
              No Followed Shops Yet
            </h4>
          )}
          <Grid container spacing={3}>
            {loading == true &&
              Array.from({ length: 3 }).map((val) => (
                <>
                  <Grid item lg={3} md={6} xs={12}>
                    <Skeleton
                      sx={{ height: 180, width: "100%", borderRadius: "20px" }}
                      animation="wave"
                      variant="rectangular"
                    />
                  </Grid>
                </>
              ))}

            {loading == false &&
              data.map((shop) => (
                <Grid item xs={12} sm={12} md={6} lg={4} key={shop.id}>
                  {console.log(shop, "sjdfkjsd")}
                  <Paper
                    style={{ border: "1px solid #444444" }}
                    sx={(theme) => ({
                      p: 2,
                      margin: "auto",
                      flexGrow: 1,
                      backgroundColor: "black",
                      color: "white",
                      ...theme.applyStyles("dark", {
                        backgroundColor: "#1A2027",
                      }),
                    })}
                  >
                    <Grid container spacing={2} style={{ padding: "20px 0px" }}>
                      <Grid item>
                        <ButtonBase sx={{ width: 128, height: 128 }}>
                          <Img
                            alt={shop?.name}
                            src={picBaseUrl + shop?.sellers?.shop_logo}
                            width={100}
                            height={100}
                            onClick={() =>
                              navigate(`/seller-shop/${shop?.sellers?.id}`)
                            }
                            style={{
                              borderRadius: "50%",
                              border: "1px solid black",
                              cursor: "pointer",
                            }}
                          />
                        </ButtonBase>
                      </Grid>
                      <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                          <Grid item xs>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                gutterBottom
                                variant="subtitle1"
                                component="div"
                              >
                                {shop?.sellers?.shop_name}
                              </Typography>
                              <button
                                className="btn btn-warning"
                                onClick={() => {
                                  handleScrollToTop();
                                  navigate(`/seller-shop/${shop?.sellers?.id}`);
                                }}
                              >
                                Visit Shop
                              </button>
                            </div>
                            <LuMapPin />
                            <span>{shop?.sellers?.shop_location}</span>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "5px",
                              }}
                            >
                              <div>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  className="text-center text-white"
                                >
                                  {shop?.sellers?.reviews_avg_rating ==
                                    undefined ||
                                  shop?.sellers?.reviews_avg_rating == null ||
                                  shop?.sellers?.reviews_avg_rating == ""
                                    ? 0
                                    : Number(
                                        shop?.sellers?.reviews_avg_rating
                                      )?.toFixed(1)}{" "}
                                  <FaStar className="text-warning" />
                                </Typography>
                                <Typography
                                  variant="body2"
                                  className="text-secondary"
                                >
                                  Rating
                                </Typography>
                              </div>
                              <div>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  className="text-center"
                                >
                                  {shop?.sellers?.seller_followers_count ==
                                    undefined ||
                                  shop?.sellers?.seller_followers_count ==
                                    null ||
                                  shop?.sellers?.seller_followers_count == ""
                                    ? 0
                                    : shop?.sellers?.seller_followers_count}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  className="text-secondary"
                                >
                                  Followers
                                </Typography>
                              </div>
                              <div>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  className="text-center"
                                >
                                  {shop?.sellers?.products_count == undefined ||
                                  shop?.sellers?.products_count == null ||
                                  shop?.sellers?.products_count == ""
                                    ? 0
                                    : shop?.sellers?.products_count}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  className="text-secondary"
                                >
                                  Products
                                </Typography>
                              </div>
                            </div>
                          </Grid>
                          <Grid item>
                            <Typography
                              sx={{ cursor: "pointer" }}
                              variant="body2"
                            >
                              <button
                                className="btn btn-outline-warning"
                                style={{ width: "100%" }}
                                onClick={() =>
                                  handleToggleFollow(shop?.sellers?.id, 0)
                                }
                              >
                                Following
                              </button>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="subtitle1"
                            component="div"
                            onClick={() =>
                              navigate(`/seller-shop/${shop?.sellers?.id}`)
                            }
                          ></Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))}
          </Grid>
        </div>
      </div>
    </>
  );
}
