import React, { useEffect } from "react";
import "./App.css";
import RoutesFile from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import { enqueueSnackbar, SnackbarProvider } from "notistack";
import { IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { QueryClient, QueryClientProvider } from "react-query";
import { I18nextProvider } from "react-i18next";
import i18n from "./components/i18n";
import { onMessage } from "firebase/messaging";
import { messaging, db } from "./firebaseConfig";
import toast, { Toaster } from "react-hot-toast";
import { collection, onSnapshot } from "firebase/firestore";
import { getToken } from "firebase/messaging";
import Message from "./components/Message";

function App() {
  const queryClient = new QueryClient();
  const notiStackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notiStackRef.current.closeSnackbar(key);
  };

  onMessage(messaging, (payload) => {
    toast.success(<Message message={payload?.notification} />, { icon: null });
  });

  useEffect(() => {
    // Request notification permission
    const requestPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          console.log("Notification permission granted.");
        } else {
          console.log("Notification permission denied.");
        }
      } catch (error) {
        console.error("Error requesting notification permission: ", error);
      }
    };

    // Call the function to request permission when the component mounts
    requestPermission();
  }, []);

  return (
    <>
      <Toaster position="bottom-right" />
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          hideIconVariant
          ref={notiStackRef}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          TransitionComponent={Slide}
          maxSnack={3}
          autoHideDuration={2000}
          action={(key) => (
            <IconButton onClick={onClickDismiss(key)}>
              <CloseIcon htmlColor="white" />
            </IconButton>
          )}
        >
          <I18nextProvider i18n={i18n}>
            <RoutesFile />
          </I18nextProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
