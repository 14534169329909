// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBvze65XWNPzcTIN51_aLHm0WIxkGjdnuI",
  authDomain: "goldstore-2ec22.firebaseapp.com",
  projectId: "goldstore-2ec22",
  storageBucket: "goldstore-2ec22.appspot.com",
  messagingSenderId: "371590429675",
  appId: "1:371590429675:web:751cd0c145f2f280d937ae",
  measurementId: "G-1JL30Y8HVW",
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const messaging = getMessaging(app);
export const auth = getAuth();
export const db = getFirestore(app);

export const GetFCMToken = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission == "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BAClIxoSn-QUiI-3LQnlK-1Cb4pldBuKtoXk4tZx4EvAHrp2IolRvcBu9GFhNzidmqwsIX-QRg7WSUAShGanb7Q", // Replace with your Firebase VAPID key
      });
      if (token) {
        localStorage.setItem("fcm-token", token);
        return token;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    } else {
      console.log("Permission not granted for notifications.");
    }
  } catch (error) {
    console.error("An error occurred while retrieving the token. ", error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
