import React, { useState } from "react";
import { Typography } from "@mui/material";
import NotificationModel from "./NotificationModel";
import { GiCardboardBoxClosed } from "react-icons/gi";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import PromotionalNotification from "./PromotionNotification";

const Notification = () => {
  const [val, setVal] = useState(0);
  return (
    <>
      <div className="mt-3">
        <div className="text-center">
          <h3 className="text-white">Notifcations</h3>
        </div>
        <div
          className="text-center"
          style={{
            margin: "auto",
          }}
        >
          <div className="d-flex gap-5 mt-3 bg-black py-2 justify-content-center text-center">
            <Typography
              variant="h6"
              className={
                val == 0
                  ? "activeItem btn bg-secondary text-white"
                  : "Item btn text-white"
              }
              onClick={() => setVal(0)}
            >
              <GiCardboardBoxClosed />
              Order
            </Typography>
            <Typography
              variant="h6"
              className={
                val == 1
                  ? "activeItem btn bg-secondary text-white"
                  : "Item  btn text-white"
              }
              onClick={() => setVal(1)}
            >
              <HiOutlineSpeakerphone />
              Promotions
            </Typography>
          </div>
          <div className="mt-1 container">
            {val == 0 ? (
              <>
                <div>
                  <NotificationModel />
                </div>
              </>
            ) : (
              <PromotionalNotification />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
