import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { GiRoundStar } from "react-icons/gi";
import { TfiShoppingCart } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import { picBaseUrl } from "../../config/config";

export default function RecommendedProducts({ recommended }) {
  const navigate = useNavigate();

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleDetail = (id) => {
    navigate(`/product-detail/${id}`);
  };
  return (
    <div
      style={{
        overflowX: "scroll",
        display: "flex",
        gap: "20px",
        scrollbarWidth: "none",
      }}
    >
      {recommended?.map((val) => (
        <>
          <div className=" mt-3" style={{ width: "auto" }}>
            <Card
              className="card cardTrending position-relative"
              sx={{
                padding: {
                  lg: "10px 10px 0px 10px",
                  md: "10px 10px 0px 10px",
                  sm: "5px 5px 0px 5px",
                  xs: "6px 6px 0px 6px",
                },
                height: {
                  lg: "330px",
                  md: "330px",
                  sm: "330px",
                  xs: "300px",
                },
                width: { lg: "15rem", md: "13rem", sm: "11rem", xs: "10rem" },
                cursor: "pointer",
              }}
              onClick={() => {
                handleScrollToTop();
                handleDetail(val?.id);
              }}
            >
              {val?.discount > 0 && (
                <button className="discountBtn">{val?.discount + "%"}</button>
              )}
              <CardMedia
                sx={{
                  height: "150px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                image={picBaseUrl + val?.thumbnail}
                alt=""
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  className="text-white"
                  sx={{
                    whiteSpace: "normal",
                    wordWrap: "breakWord",
                    fontSize: {
                      lg: "1rem",
                      md: "0.8rem",
                      sm: "0.76rem",
                      xs: "0.76rem",
                    },
                  }}
                >
                  {val?.name.length > 16
                    ? val?.name.substring(0, 16) + "..."
                    : val?.name}
                </Typography>

                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  className="text-white"
                  sx={{
                    fontSize: {
                      lg: "1rem",
                      md: "1rem",
                      sm: "0.76rem",
                      xs: "0.76rem",
                    },
                  }}
                >
                  Rs. {val?.price}
                </Typography>
                <div
                  className="d-flex justify-content-between align-items-center mt-3"
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    left: "20px",
                    right: "10px",
                  }}
                >
                  <div className="d-flex ">
                    <GiRoundStar
                      sx={{
                        fontSize: {
                          lg: "1rem",
                          md: "1rem",
                          sm: "0.76rem",
                          xs: "0.76rem",
                        },
                      }}
                      color="gold"
                    />
                    <span
                      className="mx-2  text-secondary"
                      sx={{
                        fontSize: {
                          lg: "1rem",
                          md: "1rem",
                          sm: "0.76rem",
                          xs: "0.76rem",
                        },
                      }}
                    >
                      {Number(val?.rating)?.toFixed(1)}{" "}
                      <span>({val?.reviews_count})</span>
                    </span>
                  </div>
                  <TfiShoppingCart
                    style={{ fontSize: "31px", marginTop: "10px" }}
                    color="gold"
                  />
                </div>
              </CardContent>
            </Card>
          </div>
        </>
      ))}
    </div>
  );
}
