import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoLocationOutline, IoPerson } from "react-icons/io5";
import { MdOutlineMailOutline, MdOutlinePhone } from "react-icons/md";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { FaPen } from "react-icons/fa";
import { enqueueSnackbar } from "notistack";

export default function BillingSection({ setActive }) {
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });
  const [isEnablePhone, setIsEnablePhone] = useState(false);
  const [isEnableAddress, setIsEnableAddress] = useState(false);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputs?.phone == "" || inputs?.address == "") {
      enqueueSnackbar("Please fill the required fields", { variant: "error" });
      return;
    }
    setActive(1);
    localStorage.setItem("billing_address", JSON.stringify(inputs));
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user_data"));
    setInputs({
      name: userData?.username,
      email: userData?.email,
      phone: userData?.phone_no,
      address: userData?.address,
    });
  }, []);
  return (
    <>
      <form action="" onSubmit={handleSubmit}>
        <div
          style={{
            backgroundColor: "#000000",
            padding: "30px",
            marginTop: "2rem",
            borderRadius: "10px",
          }}
        >
          <div className="mt-1">
            <Typography
              variant="subtitle1"
              className=" fw-bold d-flex gap-1 align-items-center"
            >
              <span>
                <PersonOutlineOutlinedIcon
                  style={{ color: "white", height: "26px", width: "26px" }}
                />
              </span>
              <span className="text-warning mt-1">Your Name *</span>
            </Typography>
            <Typography
              className="text-white"
              sx={{ borderBottom: "1px solid white" }}
            >
              {inputs?.name}
            </Typography>
          </div>
          <div className="mt-3">
            <Typography
              variant="subtitle1"
              className=" fw-bold d-flex gap-1 align-items-center"
            >
              <span>
                <MdOutlineMailOutline
                  style={{ color: "white", height: "25px", width: "25px" }}
                />
              </span>
              <span className="text-warning mt-1">Email *</span>
            </Typography>
            <Typography
              className="text-white"
              sx={{ borderBottom: "1px solid white" }}
            >
              {inputs?.email}
            </Typography>
          </div>
          <div className="mt-3">
            <Typography
              variant="subtitle1"
              className=" fw-bold gap-1 align-items-center"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <span>
                  <MdOutlinePhone
                    style={{
                      color: "white",
                      height: "25px",
                      width: "25px",
                      cursor: "pointer",
                    }}
                  />
                </span>
                <span className="text-warning mt-1">Phone *</span>
              </div>
              <div>
                <span>
                  <FaPen
                    style={{
                      color: "white",
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsEnablePhone(true)}
                  />
                </span>
              </div>
            </Typography>
            <TextField
              fullWidth
              id="standard-basic"
              name="phone"
              disabled={isEnablePhone == false}
              value={inputs?.phone}
              onChange={handleChange}
              variant="standard"
              InputProps={{
                style: { color: "white" },
              }}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "white",
                },

                "& .MuiInput-underline:before": {
                  borderBottomColor: "white",
                },

                "&:hover .MuiInput-underline:before": {
                  borderBottomColor: "white",
                },

                "& .MuiInput-underline:after": {
                  borderBottomColor: "gold",
                },
              }}
            />
          </div>
          <div className="mt-3">
            <Typography
              variant="subtitle1"
              className=" fw-bold gap-1 align-items-center"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <span>
                  <IoLocationOutline
                    style={{ color: "white", height: "25px", width: "25px" }}
                  />
                </span>
                <span className="text-warning mt-1">Address *</span>
              </div>
              <div>
                <span>
                  <FaPen
                    style={{
                      color: "white",
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsEnableAddress(true)}
                  />
                </span>
              </div>
            </Typography>
            <TextField
              fullWidth
              id="standard-basic"
              disabled={isEnableAddress == false}
              variant="standard"
              value={inputs?.address}
              name="address"
              onChange={handleChange}
              InputProps={{
                style: { color: "white" },
              }}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "white",
                },

                "& .MuiInput-underline:before": {
                  borderBottomColor: "white",
                },

                "&:hover .MuiInput-underline:before": {
                  borderBottomColor: "white",
                },

                "& .MuiInput-underline:after": {
                  borderBottomColor: "gold",
                },
              }}
            />
          </div>
        </div>
        <div className="text-end mt-2">
          <Button
            type="submit"
            variant="outlined"
            className="outline-btn fw-bold"
            sx={{
              color: "#ffc107",
              borderColor: "#ffc107",
              textTransform: "none",
            }}
          >
            Proceed
          </Button>
        </div>
      </form>
    </>
  );
}
