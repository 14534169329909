import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { borderRadius } from "@mui/system";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { picBaseUrl } from "../../config/config";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "92%", md: "90%", sm: "85%", xs: "90%" },
  bgcolor: "#404040",
  border: "none",
  height: "80vh",
  overflowY: "scroll",
  overflowX: "hidden",
};

export default function ImagesModel({ open, setOpen, data }) {
  const handleClose = () => setOpen(false);
  const [isShowFull, setIsShowFull] = React.useState(false);
  const [fullImage, setFullImage] = React.useState(null);
  const handleShowFull = (url) => {
    setFullImage(url);
    setIsShowFull(true);
  };
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="d-flex align-items-center justify-content-between "
            style={{
              position: "sticky",
              top: "0px",
              left: "0",
              width: "100%",
              padding: "10px",

              zIndex: 10,
              backgroundColor: "#232323",
            }}
          >
            <Typography variant="h5" className="text-warning">
              Images
            </Typography>
            {isShowFull == false ? (
              <IconButton>
                <ClearIcon
                  sx={{ color: "white" }}
                  onClick={() => handleClose()}
                />
              </IconButton>
            ) : (
              <IconButton>
                <ClearIcon
                  sx={{ color: "white" }}
                  onClick={() => setIsShowFull(false)}
                />
              </IconButton>
            )}
          </div>
          {isShowFull == false ? (
            <div className="row">
              {data?.map((val) => (
                <>
                  <div className="col-lg-3 mt-2">
                    <img
                      src={picBaseUrl + val?.file}
                      onClick={() => handleShowFull(val?.file)}
                      alt=""
                      style={{
                        height: "220px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </>
              ))}
            </div>
          ) : (
            <img
              src={picBaseUrl + fullImage}
              style={{ width: "100%", height: "auto" }}
              alt=""
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}
