import React, { useEffect, useState } from "react";
import {
  Typography,
  IconButton,
  Box,
  Button,
  Skeleton,
  Checkbox,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { DeleteItem, UpdateQuantity } from "../../DAL/Cart/Cart";
import { useSnackbar } from "notistack";
import { useScroll } from "../../hooks/AppContext";
import { picBaseUrl } from "../../config/config";
import ConfirmationModel from "../../components/ConfirmationModel";
import cartGold from "../../assets/images/cartGold.png";
import { styled } from "@mui/material/styles";

const CustomCheckbox = styled(Checkbox)({
  "& .MuiSvgIcon-root": {
    borderColor: "white",
  },
  "&.Mui-checked .MuiSvgIcon-root": {
    color: "gold",
    border: "none",
  },
});

const Cart = () => {
  const { GetCartQuantity, GetCartItem, cartItems, toggleSelectedItem } =
    useScroll();
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [itemId, setItemId] = useState("");

  const handleDecrement = async (p_id, quantity) => {
    const data = { id: p_id, quantity: quantity - 1 };
    const resp = await UpdateQuantity(data);
    if (resp?.status === true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      GetCartItem();
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  const handleIncrement = async (p_id, quantity) => {
    const data = { id: p_id, quantity: quantity + 1 };
    const resp = await UpdateQuantity(data);
    if (resp?.status === true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      GetCartItem();
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  const handleDeleteItem = (p_id) => {
    setItemId(p_id);
    setOpen(true);
  };

  const handleModelDelete = async () => {
    setOpen(false);
    const resp = await DeleteItem(itemId);
    if (resp.status === true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      GetCartItem();
      GetCartQuantity();
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  const handleCheckboxChange = (e, itemId) => {
    const selectedItem = cartItems.find((item) => item.id === itemId);
    toggleSelectedItem(itemId);

    if (e.target.checked) {
      setSelectedItems((prev) => [...prev, itemId]);
    } else {
      setSelectedItems((prev) => prev.filter((id) => id !== itemId));
    }
  };

  useEffect(() => {
    GetCartItem();
  }, []);

  useEffect(() => {
    const grandTotal = cartItems.reduce((total, item) => {
      if (selectedItems.includes(item.id)) {
        const price = parseFloat(item.products?.price.replace(/,/g, "")) || 0;
        return total + price;
      }
      return total;
    }, 0);
    setTotalAmount(grandTotal);
  }, [cartItems, selectedItems]);

  return (
    <div className="mt-3" style={{ width: "97%", margin: "auto" }}>
      <div className="" style={{ backgroundColor: "#181818" }}>
        {loading === true &&
          Array.from({ length: 2 }).map(() => (
            <Skeleton
              sx={{
                height: 80,
                width: "90%",
                margin: "auto",
                borderRadius: "10px",
                marginTop: "10px",
              }}
              animation="wave"
              variant="rectangular"
            />
          ))}
        <Box
          sx={{
            color: "#fff",
            overflowX: "scroll",
            width: "100%",
            padding: "1rem",
          }}
        >
          {loading == false && cartItems.length === 0 && (
            <div className="text-center">
              <img src={cartGold} alt="" height={100} width={100} />
              <h5 className="mt-3">Your Cart is Empty</h5>
              <p className="text-secondary">Add Products to your Cart</p>
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#FFC107",
                  color: "#FFC107",
                  textTransform: "none",
                }}
                className=" outline-btn mt-3 fw-bold"
                onClick={() => navigate("/")}
              >
                Explore Products
              </Button>
            </div>
          )}

          {loading == false && cartItems.length > 0 && (
            <>
              <div className="d-flex justify-content-between gap-3 mx-3">
                <Typography variant="h5" gutterBottom>
                  My Cart
                </Typography>
                <Button
                  variant="outlined"
                  disabled={totalAmount == 0}
                  sx={{
                    border: "1px solid #ffc107",
                    color: "#ffc107",
                    textTransform: "none",
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: "#ffc107",
                      color: "black",
                      border: "1px solid #ffc107",
                    },
                  }}
                  onClick={() => {
                    navigate("/payment-process", {
                      state: { totalAmount, selectedItems },
                    });
                    localStorage.setItem(
                      "cartItems",
                      JSON.stringify(cartItems)
                    );
                    localStorage.setItem("totalAmount", totalAmount);
                  }}
                >
                  To Pay
                </Button>
              </div>
              {cartItems.map((item) => (
                <div style={{ width: "93%", margin: "auto" }} key={item.id}>
                  {console.log(item, "jkdhskfjkljsdlkjf")}
                  <Box
                    className="row"
                    sx={{
                      backgroundColor: "#333",
                      borderRadius: "12px",
                      color: "#fff",
                      marginTop: "10px",

                      height: "120px",
                      alignItems: "center",
                    }}
                  >
                    <div className="col-4 g-0 d-flex">
                      <Checkbox
                        sx={{
                          color: "gold",
                          "&.Mui-checked": {
                            color: "gold",
                          },
                        }}
                        checked={selectedItems.includes(item?.id)}
                        onChange={(e) => handleCheckboxChange(e, item?.id)}
                      />
                      <Box
                        component="img"
                        onClick={() =>
                          navigate(`/product-detail/${item?.product_id}`)
                        }
                        src={picBaseUrl + item?.products?.thumbnail}
                        alt=""
                        sx={{
                          height: 100,
                          width: 100,
                          borderRadius: "12px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    <div className="col-8 px-5">
                      <div className="row">
                        <div className="col-lg-6">
                          <h5 className="text-cart text-warning">
                            {item?.products?.name.length > 20
                              ? item?.products?.name.substring(0, 20) + "..."
                              : item?.products?.name}
                          </h5>
                          <Typography
                            variant="body1"
                            color="white"
                            className="text-cart"
                          >
                            Rs. {item?.products?.price}
                          </Typography>
                        </div>
                        <div
                          className="col-lg-6"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-center mt-2">
                            <IconButton
                              disabled={item?.quantity == 1}
                              onClick={() =>
                                handleDecrement(item?.id, item?.quantity)
                              }
                            >
                              <RemoveIcon
                                sx={{
                                  color: item?.quantity == 1 ? "grey" : "#fff",
                                  backgroundColor: "#373D35",
                                  borderRadius: "3px",
                                }}
                              />
                            </IconButton>
                            <Typography
                              variant="subtitle1"
                              color="gold"
                              style={{
                                minWidth: "30px",
                                textAlign: "center",
                                marginTop: "5px",
                              }}
                            >
                              {item?.quantity}
                            </Typography>
                            <IconButton
                              onClick={() =>
                                handleIncrement(item?.id, item?.quantity)
                              }
                            >
                              <AddIcon
                                sx={{
                                  color: "#fff",
                                  backgroundColor: "#373D35",
                                  borderRadius: "3px",
                                }}
                              />
                            </IconButton>
                          </div>

                          <div className="text-end">
                            <IconButton
                              onClick={() => handleDeleteItem(item?.id)}
                            >
                              <DeleteIcon sx={{ color: "white" }} />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                </div>
              ))}
              <Box
                sx={{
                  backgroundColor: "#ffc107",
                  color: "black",
                  padding: "16px",
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography className="fw-bold">TOTAL AMOUNT :</Typography>
                <Typography className="fw-bold">
                  Rs. {totalAmount}.00
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </div>

      <ConfirmationModel
        open={open}
        setOpen={setOpen}
        title={"Are you sure you want to delete this item?"}
        heading={"Delete Cart Item"}
        handleDeleteData={handleModelDelete}
      />
    </div>
  );
};

export default Cart;
