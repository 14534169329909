import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { borderRadius, height } from "@mui/system";
import { MdCancel } from "react-icons/md";
import { GiCardboardBoxClosed } from "react-icons/gi";
import {
  DeletedAllItems,
  DeletedItem,
  ReadNotification,
  ShowNotification,
} from "../../DAL/Notification/Notification";
import { picBaseUrl } from "../../config/config";
import moment from "moment/moment";
import { useSnackbar } from "notistack";
import { useScroll } from "../../hooks/AppContext";
import { Card, CardContent, IconButton, Skeleton } from "@mui/material";
import BackspaceIcon from "@mui/icons-material/Backspace";
import ClearIcon from "@mui/icons-material/Clear";
import ConfirmationModel from "../../components/ConfirmationModel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "70%", md: "80%", sm: "92%", xs: "92%" },
  bgcolor: "#333333",
  borderRadius: "10px",
  // height: "80vh",
  overflowY: "scroll",
  scrollbarWidth: "none",
};

export default function NotificationModel({ open, setOpen }) {
  const handleClose = () => setOpen(false);
  const [notifications, setNotifications] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [clickedCardId, setClickedCardId] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationId, setNotificationId] = useState("");

  const GetNotification = async () => {
    const resp = await ShowNotification();
    if (resp?.status == true) {
      setNotifications(resp?.data);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    const resp = await DeletedItem(id);
    if (resp?.status == true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      GetNotification();
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  const handleAllDelete = async () => {
    setOpenNotification(true);
  };

  const handleModelDelete = async () => {
    const resp = await DeletedAllItems();
    if (resp?.status == true) {
      setOpenNotification(false);
      enqueueSnackbar(resp?.message, { variant: "success" });
      GetNotification();
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  const handleNotificationRead = async (e, id) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("id", id);
    formData.append("_method", "put");
    const resp = await ReadNotification(formData);
    if (resp?.status == true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      setClickedCardId(true);
      GetNotification();
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  useEffect(() => {
    GetNotification();
  }, []);

  return (
    <div>
      <div
        style={{
          border: "1px solid black",
          borderRadius: "12px",
          backgroundColor: "#2C2C2C",
          marginTop: "30px",
        }}
      >
        {loading === true &&
          Array.from({ length: 4 }).map(() => (
            <>
              <Skeleton
                sx={{
                  height: 100,
                  width: "100%",
                  borderRadius: "10px",
                  marginTop: "10px",
                }}
                animation="wave"
                variant="rectangular"
              />
            </>
          ))}

        {loading == false && (
          <Box
            sx={{
              color: "#fff",
              width: "100%",

              height: "auto",
              borderRadius: "10px",
            }}
          >
            <div className="p-3">
              {notifications?.length > 0 && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    variant="p"
                    align="right"
                    sx={{ mb: 2, cursor: "pointer" }}
                  >
                    Mark All Read
                  </Typography>
                  <Typography
                    variant="p"
                    align="right"
                    sx={{ mb: 2, cursor: "pointer" }}
                    onClick={() => handleAllDelete()}
                  >
                    Clear All
                  </Typography>
                </div>
              )}
              {notifications?.length == 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  <h3 className="text-white">No Notification Available</h3>
                </div>
              )}
              {notifications?.map((notification) => (
                <>
                  {console.log(notification, "jdkshfgkdkljgl")}
                  <Card
                    key={notification.id}
                    sx={{
                      alignItems: "center",
                      mb: 2,
                      backgroundColor: "black",
                      color: "#fff",
                      borderRadius: "12px",
                      height: "auto",
                      padding: "10px",
                      boxShadow:
                        notification?.is_read == 1
                          ? "none"
                          : "-5px 0px 0px 0px #ffbf00",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="d-flex align-items-center gap-3">
                        <GiCardboardBoxClosed
                          style={{
                            fontSize: "30px",
                            backgroundColor: "gold",
                            color: "black",
                            borderRadius: "50%",
                          }}
                        />
                        <div>
                          <Typography
                            variant="body3"
                            fontWeight="bold"
                            sx={{ fontSize: "12px" }}
                          >
                            {JSON.parse(notification?.message)?.title}
                          </Typography>{" "}
                          <br />
                          <Typography
                            variant="body5"
                            align="right"
                            sx={{
                              color: "grey",
                              fontWeight: 600,
                              fontSize: "10px",
                            }}
                          >
                            {moment(notification?.created_at).format(
                              "YYYY-MM-DD"
                            )}
                          </Typography>
                        </div>
                      </div>
                      <IconButton
                        onClick={() => handleDelete(notification.id)}
                        sx={{ color: "silver", cursor: "pointer" }}
                      >
                        <MdCancel />
                      </IconButton>
                    </div>{" "}
                    <br />
                    <div
                      className="d-flex"
                      onClick={(e) =>
                        handleNotificationRead(e, notification.id)
                      }
                    >
                      <Box
                        component="img"
                        src={picBaseUrl + notification?.product?.thumbnail}
                        alt=""
                        sx={{
                          width: { lg: 60, mg: 60, sm: 80, xs: 60 },
                          height: { lg: 60, mg: 60, sm: 80, xs: 60 },
                          borderRadius: "20%",
                          // margin: "10px",
                        }}
                      />
                      <CardContent
                        sx={{
                          flexGrow: 1,
                          display: "flex",
                          justifyContent: "space-between",
                          backgroundColor: "#232323",
                        }}
                      >
                        <div>
                          <Typography
                            variant="body5"
                            sx={{
                              color: "lightgray",
                              mb: 1,
                              fontSize: {
                                lg: "14px",
                                md: "13px",
                                sm: "11px",
                                xs: "10px",
                              },
                            }}
                          >
                            {JSON.parse(notification?.message)?.body.length > 70
                              ? JSON.parse(
                                  notification?.message
                                )?.body.substring(0, 70) + "..."
                              : JSON.parse(notification?.message)?.body}
                          </Typography>
                        </div>
                      </CardContent>
                    </div>
                  </Card>
                </>
              ))}
            </div>
          </Box>
        )}
      </div>

      <ConfirmationModel
        open={openNotification}
        setOpen={setOpenNotification}
        heading="Delete Notification"
        title={"Are you sure you want to delete?"}
        handleDeleteData={handleModelDelete}
      />
    </div>
  );
}
