import React, { useEffect, useState } from "react";
import { Stepper, Step, StepLabel, StepConnector } from "@mui/material";
import { styled } from "@mui/material/styles";
import BillingSection from "./BillingSection";
import ShippingMethod from "./ShippingMethod";
import Invoice from "./Invoice";
import CompleteOrder from "./CompleteOrder";
import { useLocation, useNavigate } from "react-router-dom";

// Custom stepper styling
const StepIconContainer = styled("div")(({ theme, active }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 40,
  height: 40,
  borderRadius: "50%",
  backgroundColor: active ? "#fff" : "#424242",
  color: active ? "#000" : "#fff",
  border: active ? "2px solid #ccc" : "none",
  fontWeight: "bold",
}));

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    borderTopStyle: "solid",
    borderColor: "#424242",
  },
}));

const steps = ["Customer Information", "Select Method", "Invoice", "Completed"];

const PaymentStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { state } = useLocation();

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prev) => prev + 1);
    }
  };

  return (
    <div style={{ width: "85%", margin: "auto" }}>
      <div className="text-start mt-4"></div>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<CustomConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={() => (
                <StepIconContainer
                  active={activeStep === index}
                  className="billing-adjust"
                  sx={{
                    color: "black",
                    fontSize: "25px",
                    zIndex: 1,
                  }}
                >
                  {index + 1}
                </StepIconContainer>
              )}
            >
              <span
                style={{
                  color: activeStep === index ? "#fff" : "#7d7d7d",
                  fontWeight: activeStep === index ? "bold" : "bold",
                }}
              >
                {label}
              </span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className=" ">
        {activeStep == 0 ? (
          <BillingSection setActive={setActiveStep} />
        ) : activeStep == 1 ? (
          <ShippingMethod setActive={setActiveStep} amount={state} />
        ) : activeStep == 2 ? (
          <Invoice setActive={setActiveStep} />
        ) : activeStep == 3 ? (
          <CompleteOrder />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default PaymentStepper;
