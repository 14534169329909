import React, {
  createContext,
  useRef,
  useContext,
  useState,
  useEffect,
} from "react";
import { CartListing, CartQuantity } from "../DAL/Cart/Cart";
import { GetNotification } from "../DAL/Notification/Notification";
import { useNavigate } from "react-router-dom";

const Context = createContext();

export const AppContext = ({ children }) => {
  const sectionRefs = useRef({});
  const navigate = useNavigate();
  const sectionsRefs = useRef({});
  const [quantity, setQuantity] = useState(0);
  const [notificationQuantity, setNotificationQuantity] = useState(0);
  const [token, setToken] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const GetCartQuantity = async () => {
    const resp = await CartQuantity();
    if (resp?.status == true) {
      setQuantity(resp?.data);
    }
  };
  const GetBellNotification = async () => {
    const resp = await GetNotification();
    if (resp?.status == true) {
      setNotificationQuantity(resp?.data);
    }
  };
  const GetCartItem = async () => {
    const resp = await CartListing();
    if (resp.status === true) {
      setCartItems(resp?.data);
    }
  };
  const NoToken = () => {
    if (!localStorage.getItem("web_token")) {
      navigate("/pre-login");
    }
  };
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toggleSelectedItem = (itemId) => {
    const updatedSelectedItems = selectedItems.includes(itemId)
      ? selectedItems.filter((id) => id !== itemId)
      : [...selectedItems, itemId];

    setSelectedItems(updatedSelectedItems); // update the selectedItems state
  };

  useEffect(() => {
    GetCartQuantity();
    GetBellNotification();
  }, []);

  const bundle = {
    sectionRefs,
    sectionsRefs,
    GetCartQuantity,
    quantity,
    handleScrollToTop,
    GetCartItem,
    cartItems,
    notificationQuantity,
    GetBellNotification,
    NoToken,
    selectedItems, 
    toggleSelectedItem,
  };

  return <Context.Provider value={bundle}>{children}</Context.Provider>;
};

export const useScroll = () => useContext(Context);
