import { invokeApi } from "../../utils";

export const PendingListing = async (id) => {
  const requestObj = {
    path: `/api/user/order/pending/list`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const ProgressListing = async (id) => {
  const requestObj = {
    path: `/api/user/order/progress/list`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const CompleteListing = async (id) => {
  const requestObj = {
    path: `/api/user/order/completed/list`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const DeliveredListing = async (id) => {
  const requestObj = {
    path: `/api/user/order/delivered/list`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const OrderDeliveredImages = async (id) => {
  const requestObj = {
    path: `/api/user/order/delivered/file/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const OrderCompletionImages = async (id) => {
  const requestObj = {
    path: `/api/user/order/completed/file/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const OrderSave = async (data) => {
  const requestObj = {
    path: `/api/user/order/save`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
