import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { GiRoundStar } from "react-icons/gi";
import { TfiShoppingCart } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import { TopTrendings } from "../../DAL/TrendingProducts/Trending";
import { Box, CardMedia, Chip, Skeleton } from "@mui/material";
import { picBaseUrl } from "../../config/config";
import { useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";

export default function TrendingSlider() {
  const navigate = useNavigate();

  const [data, setData] = React.useState(
    JSON.parse(localStorage.getItem("trending_data")) || []
  );

  const GetTrendingProducts = async () => {
    const resp = await TopTrendings();
    if (resp?.status == true) {
      localStorage.setItem("trending_data", JSON.stringify(resp?.data));
      return resp?.data;
    }
  };

  const { isLoading, isError, error } = useQuery(
    "trendingList",
    GetTrendingProducts,
    {
      initialData: data,
      staleTime: 0,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      onSuccess: (newData) => {
        setData(newData);
      },
    }
  );

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (isError) {
    return enqueueSnackbar(error?.message, { variant: "error" });
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        {data?.length > 0 && (
          <>
            <Typography variant="h6" className="text-white">
              Trendings
            </Typography>
            <Chip
              label="See All"
              sx={{
                backgroundColor: "black",
                color: "gold",
                cursor: "pointer",
                "&:hover": { backgroundColor: "black" },
              }}
              onClick={() => {
                handleScrollToTop();
                navigate("/trending-products");
              }}
            />
          </>
        )}
      </div>

      <div className="cardMain">
        {isLoading &&
          Array.from({ length: 4 }).map(() => (
            <>
              <Skeleton
                sx={{ height: 440, width: 325, borderRadius: "20px" }}
                animation="wave"
                variant="rectangular"
              />
            </>
          ))}
        {!isLoading &&
          data?.map((val) => (
            <>
              <Card
                className="card cardTrending position-relative"
                sx={{
                  padding: {
                    lg: "10px 10px 0px 10px",
                    md: "10px 10px 0px 10px",
                    sm: "5px 5px 0px 5px",
                    xs: "6px 6px 0px 6px",
                  },
                  height: {
                    lg: "330px",
                    md: "330px",
                    sm: "330px",
                    xs: "300px",
                  },
                  width: { lg: "15rem", md: "13rem", sm: "11rem", xs: "10rem" },
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleScrollToTop();
                  navigate(`/product-detail/${val?.id}`);
                }}
              >
                {val?.discount !== 0 && (
                  <button className="discountBtn">{val?.discount + "%"}</button>
                )}
                <CardMedia
                  sx={{
                    // height: { lg: "150px", md: 170, sm: 130, xs: 120 },
                    height: "150px",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  image={picBaseUrl + val?.thumbnail}
                  alt=""
                />

                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1, // This will make the content fill the vertical space
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    className="text-white"
                    sx={{
                      whiteSpace: "normal",
                      wordWrap: "breakWord",
                      fontSize: {
                        lg: "1rem",
                        md: "0.8rem",
                        sm: "0.76rem",
                        xs: "0.76rem",
                      },
                    }}
                  >
                    {val?.name.length > 30
                      ? val?.name.substring(0, 30) + "..."
                      : val?.name}
                  </Typography>

                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    className="text-white "
                    sx={{
                      fontSize: {
                        lg: "1rem",
                        md: "1rem",
                        sm: "0.76rem",
                        xs: "0.76rem",
                      },
                    }}
                  >
                    Rs. {val?.price}
                  </Typography>
                  <div
                    className="d-flex justify-content-between align-items-end"
                    style={{
                      position: "absolute",
                      bottom: "20px",
                      left: "20px",
                      right: "10px",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <GiRoundStar
                        sx={{
                          fontSize: {
                            lg: "1rem",
                            md: "1rem",
                            sm: "0.76rem",
                            xs: "0.76rem",
                          },
                        }}
                        color="gold"
                      />
                      <span
                        className="mx-2 mt-1 text-secondary"
                        sx={{
                          fontSize: {
                            lg: "1rem",
                            md: "1rem",
                            sm: "0.76rem",
                            xs: "0.76rem",
                          },
                        }}
                      >
                        {Number(val?.rating)?.toFixed(1)}{" "}
                        <span>({val?.reviews_count})</span>
                      </span>
                    </div>
                    <TfiShoppingCart
                      style={{ fontSize: "31px", marginTop: "5px" }}
                      color="gold"
                    />
                  </div>
                </CardContent>
              </Card>
            </>
          ))}
      </div>
    </>
  );
}
