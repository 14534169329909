import {
  CardMedia,
  Chip,
  Pagination,
  Card,
  Typography,
  CardContent,
  Skeleton,
  Box,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { GiRoundStar } from "react-icons/gi";
import { TfiShoppingCart } from "react-icons/tfi";
import {
  GetAllProductCategories,
  SellerCategoryProducts,
} from "../../DAL/Categories/Category";
import { useNavigate } from "react-router-dom";
import { picBaseUrl } from "../../config/config";
import { useScroll } from "../../hooks/AppContext";

export default function AllCategories() {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [catalogList, setCatalogList] = useState([]);
  const [activeId, setActiveId] = useState();
  const { handleScrollToTop } = useScroll();
  const [catalogProductList, setCatalogProductList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const GetCatalogList = async () => {
    const resp = await GetAllProductCategories();
    if (resp?.status == true) {
      setCatalogList(resp?.data);
      setActiveId(resp?.data[0]?.id);
    }
  };

  const GetCatalogProducts = async () => {
    setLoading(true);
    const resp = await SellerCategoryProducts(activeId, page);
    if (resp?.status == true) {
      setCatalogProductList(resp?.data?.data);
      setTotalPages(resp?.data?.last_page);
      setLoading(false);
    }
  };

  const handleSelectCatalog = async (id) => {
    setActiveId(id);
    GetCatalogProducts();
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    GetCatalogList();
  }, []);
  useEffect(() => {
    GetCatalogProducts();
  }, [page, activeId]);
  return (
    <>
      <div
        className="mt-2 text-start"
        style={{
          width: { lg: "60%", md: "60%", sm: "70%", xs: "80%" },
          margin: "auto",
        }}
      >
        {catalogList?.map((val) => (
          <Chip
            label={
              val?.title.length > 20
                ? val?.title?.substring(0, 20) + "..."
                : val?.title
            }
            variant={"outlined"}
            onClick={() => handleSelectCatalog(val?.id)}
            className={
              val?.id == activeId
                ? "activeCatalog mx-1 mt-4"
                : "text-warning mx-1 mt-4"
            }
            sx={{ borderColor: "gold" }}
          />
        ))}
      </div>
      {loading == false && catalogProductList?.length == 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5 className="text-white mt-5">No Products Available</h5>
        </div>
      )}
      <Grid container spacing={2}>
        {loading === true &&
          Array.from({ length: 20 }).map(() => (
            <>
              <Grid item lg={2} md={3} sm={6} xs={6}>
                <Skeleton
                  sx={{
                    height: { lg: 320, sm: 280, xs: 280 },
                    width: "100%",
                    borderRadius: "20px",
                    marginTop: "2rem",
                  }}
                  animation="wave"
                  variant="rectangular"
                />
              </Grid>
            </>
          ))}

        {loading == false &&
          catalogProductList?.map((val) => (
            <>
              <Grid item lg={2} md={3} sm={6} xs={6} sx={{ marginTop: "30px" }}>
                <Card
                  className="card favouriteCards position-relative"
                  sx={{
                    padding: {
                      lg: "10px 10px 0px 10px",
                      md: "10px 10px 0px 10px",
                      sm: "5px 5px 0px 5px",
                      xs: "6px 6px 0px 6px",
                    },
                    height: {
                      lg: "330px",
                      md: "330px",
                      sm: "330px",
                      xs: "300px",
                    },
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/product-detail/${val?.id}`);
                    handleScrollToTop();
                  }}
                >
                  {val?.discount == 0 ? (
                    ""
                  ) : (
                    <button className="discountBtn">
                      {val?.discount + "%"}
                    </button>
                  )}

                  <CardMedia
                    sx={{
                      height: "150px",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    image={picBaseUrl + val?.thumbnail}
                    alt=""
                  />
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      className="text-white"
                      sx={{
                        whiteSpace: "normal",
                        wordWrap: "breakWord",
                        fontSize: {
                          lg: "1rem",
                          md: "0.8rem",
                          sm: "0.76rem",
                          xs: "0.76rem",
                        },
                      }}
                    >
                      {val?.name?.length > 30
                        ? val?.name.substring(0, 30) + "..."
                        : val?.name}
                    </Typography>

                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      className="text-white"
                      sx={{
                        whiteSpace: "normal",
                        wordWrap: "breakWord",
                        fontSize: {
                          lg: "1rem",
                          md: "0.8rem",
                          sm: "0.76rem",
                          xs: "0.76rem",
                        },
                      }}
                    >
                      RS: {val?.price}
                    </Typography>
                    <div
                      className="d-flex justify-content-between align-items-center mt-3"
                      style={{
                        position: "absolute",
                        bottom: "20px",
                        left: "20px",
                        right: "10px",
                      }}
                    >
                      <div className="d-flex ">
                        <GiRoundStar
                          sx={{
                            whiteSpace: "normal",
                            wordWrap: "breakWord",
                            fontSize: {
                              lg: "1rem",
                              md: "0.8rem",
                              sm: "0.76rem",
                              xs: "0.76rem",
                            },
                          }}
                          color="gold"
                        />
                        <span
                          className="mx-2  text-secondary"
                          sx={{
                            whiteSpace: "normal",
                            wordWrap: "breakWord",
                            fontSize: {
                              lg: "1rem",
                              md: "0.8rem",
                              sm: "0.76rem",
                              xs: "0.76rem",
                            },
                          }}
                        >
                          {Number(val?.rating)?.toFixed(1)}{" "}
                          <span>({val?.reviews_count})</span>
                        </span>
                      </div>
                      <TfiShoppingCart
                        style={{ fontSize: "31px", marginTop: "10px" }}
                        color="gold"
                      />
                    </div>
                  </CardContent>
                </Card>
                {/* </div> */}
              </Grid>
            </>
          ))}
      </Grid>
      <div className=" d-flex justify-content-center mt-5">
        <Pagination
          count={totalPages}
          page={page}
          siblingCount={0}
          onChange={handlePageChange}
          sx={{
            mt: 4,
            display: "flex",
            justifyContent: "center",
            "& .MuiPaginationItem-root": {
              color: "white",
              borderColor: "gold",
            },
            "& .MuiPaginationItem-root.Mui-selected": {
              backgroundColor: "gold",
              color: "black",
            },
            "& .MuiPaginationItem-root:hover": {
              backgroundColor: "gold",
              color: "black",
            },
          }}
          shape="rounded"
        />
      </div>
    </>
  );
}
