import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import { WebFeedBackApi } from "../../DAL/WebFeedBack/Feedback";
import { enqueueSnackbar } from "notistack";

const CareerDetails = () => {
  const [inputs, setInputs] = useState({
    phone: "",
    firstName: "",
    lastName: "",
    email: "",
    description: "",
  });
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const handlePhoneno = (e) => {
    const { value } = e.target;
    // Ensure the value is non-negative and numeric
    if (value === "" || /^[0-9]*$/.test(value)) {
      setInputs((prev) => ({ ...prev, phone: value }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("first_name", inputs?.firstName);
    formData.append("last_name", inputs?.lastName);
    formData.append("email", inputs?.email);
    formData.append("phone_no", inputs?.phone);
    formData.append("comment", inputs?.description);

    const resp = await WebFeedBackApi(formData);
    if (resp?.status == true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      setInputs({
        phone: "",
        firstName: "",
        lastName: "",
        email: "",
        description: "",
      });
      setLoading(false);
    } else {
      setErrors(resp?.message);
      setLoading(false);
      if (typeof resp?.message == "string") {
        enqueueSnackbar(resp?.message, { variant: "error" });
      } else {
        enqueueSnackbar("Please fill the required fields", {
          variant: "error",
        });
      }
    }
  };
  return (
    <>
      <div className="container mt-4">
        <div className="text-center">
          <h2 className="text-warning">Feedback</h2>
          <h3 className="text-white">
            To get feedback about our services or app.
          </h3>
        </div>
        <div className="container gx-5 mt-3">
          <Box
            component="form"
            sx={{
              "& .MuiInputBase-root": { color: "white" },
              "& .MuiInputLabel-root": { color: "white" },
              "& .MuiInputLabel-root.Mui-focused": { color: "white" },
              "& .MuiInput-underline:before": { borderBottomColor: "white" },
              "& .MuiInput-underline:after": { borderBottomColor: "white" },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="row">
              <div className="col-lg-6">
                <TextField
                  required
                  id="standard-required"
                  label="First Name"
                  fullWidth
                  variant="standard"
                  value={inputs.firstName}
                  onChange={(e) =>
                    setInputs({ ...inputs, firstName: e.target.value })
                  }
                />

                <FormHelperText className="text-danger">
                  {errors?.first_name && errors?.first_name[0]}
                </FormHelperText>
              </div>
              <div className="col-lg-6">
                <TextField
                  required
                  id="standard-required"
                  label="Last Name"
                  fullWidth
                  variant="standard"
                  value={inputs.lastName}
                  onChange={(e) =>
                    setInputs({ ...inputs, lastName: e.target.value })
                  }
                />
                <FormHelperText className="text-danger">
                  {errors?.last_name && errors?.last_name[0]}
                </FormHelperText>
              </div>

              <div className="col-lg-6">
                <TextField
                  required
                  id="standard-required"
                  label="Email"
                  fullWidth
                  variant="standard"
                  value={inputs.email}
                  onChange={(e) =>
                    setInputs({ ...inputs, email: e.target.value })
                  }
                />
                <FormHelperText className="text-danger">
                  {errors?.email && errors?.email[0]}
                </FormHelperText>
              </div>
              <div className="col-lg-6">
                <TextField
                  required
                  id="standard-number"
                  fullWidth
                  label="Phone no"
                  onChange={handlePhoneno}
                  value={inputs.phone}
                  type="text"
                  variant="standard"
                />
                <FormHelperText className="text-danger">
                  {errors?.phone_no && errors?.phone_no[0]}
                </FormHelperText>
              </div>
              <div className="col-12">
                <h6 className="text-white mt-2">Comment</h6>
                <textarea
                  className="form-control text-white"
                  id="exampleFormControlTextarea1"
                  rows="7"
                  style={{
                    backgroundColor: "transparent",
                    color: "white",
                    border: "1px solid white",
                    outline: "none",
                    caretColor: "white",
                    boxShadow: "none",
                  }}
                  value={inputs.description}
                  onChange={(e) =>
                    setInputs({ ...inputs, description: e.target.value })
                  }
                ></textarea>
                <FormHelperText className="text-danger">
                  {errors?.comment && errors?.comment[0]}
                </FormHelperText>
              </div>
            </div>
          </Box>
        </div>
        <div className="pb-5 mx-1 mt-3">
          <Button
            disabled={loading == true}
            variant={"contained"}
            className="addtoCart text-dark mx-4"
            onClick={handleSubmit}
          >
            {loading == true ? (
              <CircularProgress sx={{ color: "black" }} size={20} />
            ) : (
              "Send Your Message"
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CareerDetails;
