import { Button, Typography } from "@mui/material";
import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function CompleteOrder() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        backgroundColor: "#000000",
        padding: "50px",
        marginTop: "2rem",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
      }}
    >
      <div className="d-flex align-items-center gap-3">
        <FaRegCheckCircle
          style={{ height: "80px", width: "80px", color: "gold" }}
        />
        <div>
          <Typography className="text-secondary">
            Order Id 378264897329849
          </Typography>
          <Typography variant="h5" className="text-white">
            Thank You !
          </Typography>
          <Typography className="text-white">
            your order is under process
          </Typography>
        </div>
      </div>
      <Button
        variant="outlined"
        className="outline-btn mt-3 fw-bold"
        sx={{ color: "#ffc107", borderColor: "#ffc107", textTransform: "none" }}
        onClick={() => {
          navigate("/");
        }}
      >
        Continue shopping
      </Button>
    </div>
  );
}
