import React, { useEffect, useState } from "react";
import logo from "../../assets/images/Goldlogo.png";
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import { RxCross2 } from "react-icons/rx";
import { LoginApi } from "../../DAL/Auth/auth";
import { useSnackbar } from "notistack";
import {
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";

import Iconify from "../../components/Iconify";
import Cookies from "js-cookie";
import { GetFCMToken } from "../../firebaseConfig";
import { messaging } from "../../firebaseConfig";
import { getToken } from "firebase/messaging";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#181818",
  boxShadow:
    "0px 4px 10px #534005, 4px 0px 10px #534005, -4px 0px 10px #534005, 0px -4px 10px #534005",
  p: 4,
  animation: "slideDown 0.5s ease",
  borderRadius: "12px",
};

const BlurBackdrop = styled("div")({
  backdropFilter: "blur(8px)",
});

const globalStyles = `
@keyframes slideDown {
  from {
    transform: translate(-50%, -60%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}
`;

const Login = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState([]);
  const [showPass, setShowPass] = useState(false);
  const handleClose = () => setOpen(false);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [address, setAddress] = useState(null);
  const [fcmToken, setFcmToken] = useState(null);
  const handleShowPassword = () => {
    setShowPass((show) => !show);
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isChecked == true) {
      Cookies.set("useremail", inputs?.email, { expires: 7 });
      Cookies.set("password", inputs?.password, { expires: 7 });
    } else {
      Cookies.remove("useremail");
      Cookies.remove("password");
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("email", inputs?.email);
    formData.append("password", inputs?.password);
    formData.append("fcm", fcmToken);

    const resp = await LoginApi(formData);

    if (resp?.status === true) {
      localStorage.setItem("web_token", resp?.token);
      localStorage.setItem("user_data", JSON.stringify(resp?.user));
      enqueueSnackbar(resp?.message, { variant: "success" });
      navigate("/");
    } else {
      setIsLoading(false);
      if (typeof resp?.message == "string") {
        enqueueSnackbar(resp?.message, { variant: "error" });
      } else {
        setError(resp?.message);
        enqueueSnackbar("Please fill the required fileds", {
          variant: "error",
        });
      }
    }
  };

  const getToken = async () => {
    const token = await GetFCMToken();
    setFcmToken(token);
  };

  useEffect(() => {
    getToken();
    const savedUseremail = Cookies.get("useremail");
    const savedPassword = Cookies.get("password");
    // const fcmToken = localStorage.getItem("fcm-token");
    document.title = "GoldBazaar||Login";
    // if (fcmToken) {
    //   setFcmToken(fcmToken);
    // }
    if (savedUseremail && savedPassword) {
      setInputs((pre) => ({ ...pre, ["email"]: savedUseremail }));
      setInputs((pre) => ({ ...pre, ["password"]: savedPassword }));

      setIsChecked(true); // Since the cookies exist, mark remember me as checked
    }
    navigator.geolocation.getCurrentPosition((pos) => {
      const { latitude, longitude } = pos.coords;
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      fetch(url)
        .then((res) => res.json())
        .then((data) => setAddress(data.address));
    });
  }, []);

  return (
    <>
      <div className="text-center text-white" style={{ height: "100vh" }}>
        <img src={logo} alt="" width={130} height={100} className="mt-4" />
        <div className="Sign-up mt-5 p-5">
          <h4>Welcome Back</h4>
          <p className="text-center">
            Your Location:{" "}
            <span className="text-warning">
              {address?.country || "Unknown"}
            </span>
          </p>
          <div className="text-start">
            <label htmlFor="">Email *</label>
            <input
              type="email"
              value={inputs?.email}
              className="input p-2"
              name="email"
              onChange={handleChange}
            />
            <FormHelperText className="text-danger" sx={{ fontSize: "10px" }}>
              {error?.email && error?.email[0]}
            </FormHelperText>
            <br />
            <div>
              <label>Password *</label>
            </div>
            <TextField
              value={inputs?.password}
              onChange={handleChange}
              fullWidth
              size="small"
              name="password"
              autoComplete="current-password"
              type={showPass ? "text" : "password"}
              InputLabelProps={{
                style: { color: "silver" }, // Text color for label
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify
                        icon={showPass ? "eva:eye-fill" : "eva:eye-off-fill"}
                        sx={{ color: "white" }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                input: { color: "white" }, // Text color for input
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "silver", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "silver", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "silver", // Border color on focus
                  },
                },
              }}
            />

            <FormHelperText className="text-danger" sx={{ fontSize: "10px" }}>
              {error?.password && error?.password[0]}
            </FormHelperText>
            <br />
            <div className="text-center mt-3">
              <Button
                variant="contained"
                disabled={isLoading} // Disable when loading
                className="submitBtn w-100 fw-bold"
                onClick={handleSubmit}
                sx={{ textTransform: "none" }}
              >
                {isLoading ? (
                  <CircularProgress size={22} sx={{ color: "black" }} />
                ) : (
                  "Sign In"
                )}
              </Button>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => setIsChecked(e.target.checked)}
                    className="text-white mt-2"
                    checked={isChecked == true}
                    style={{ cursor: "pointer" }}
                  />{" "}
                  <span>Remember Me</span>
                </div>
                <div>
                  <p
                    className="text-warning mt-2"
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => navigate("/forget-password")}
                  >
                    Forgot Password
                  </p>
                </div>
              </div>
              <p className="text-center mt-2">
                Don’t have an account?{" "}
                <span
                  className="text-warning"
                  onClick={() => navigate("/sign-up")}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  Sign Up
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <style>{globalStyles}</style>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            component: BlurBackdrop,
          }}
        >
          <div className="text-center">
            <Box sx={style}>
              <div className="text-end">
                <RxCross2 className="text-white h3" />
              </div>
              <Typography id="modal-modal-title" variant="h1" component="h1">
                <FaCheckCircle style={{ color: "#D5AD3B" }} />
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h6"
                className="text-white"
                sx={{ mt: 2 }}
              >
                Sign in Successful
              </Typography>
              <Typography
                id="modal-modal-description"
                className="text-secondary"
                sx={{ mt: 2 }}
              >
                Please Wait......
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h3"
                component="h3"
                className="text-white"
                sx={{ mt: 2 }}
              >
                <FaRegCircle style={{ color: "#D5AD3B" }} />
              </Typography>
            </Box>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Login;
