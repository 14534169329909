import * as React from "react";
import Box from "@mui/material/Box";
import { Chip, Typography } from "@mui/material";
import ReviewSection from "./ReviewSection";
import AddReview from "./AddReview";
import { FaArrowLeft } from "react-icons/fa6";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import EditIcon from "@mui/icons-material/Edit";
import EditReview from "./EditReview";
export default function DescriptionSection({ description }) {
  const [value, setValue] = React.useState(0);
  const [val, setVal] = React.useState(0);

  return (
    <Box sx={{ width: "100%" }}>
      <div
        style={{
          margin: "30px auto 0px auto",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid grey",
        }}
      >
        <div
          className="d-flex gap-5"
          // style={{ borderBottom: "1px solid grey" }}
        >
          <Typography
            variant="h6"
            sx={{ cursor: "pointer" }}
            className={value === 0 ? "activeItemShop" : "Item text-secondary"}
            onClick={() => setValue(0)}
          >
            Description
          </Typography>
          <Typography
            variant="h6"
            sx={{ cursor: "pointer" }}
            className={value === 1 ? "activeItemShop" : "Item text-secondary"}
            onClick={() => setValue(1)}
          >
            Reviews
          </Typography>
        </div>
        {value === 1 && val === 0 && (
          <div>
            <Chip
              icon={<EditIcon style={{ fontSize: "18px", color: "black" }} />}
              onClick={() => setVal(1)}
              sx={{
                backgroundColor: "#FFC107",
                height: "25px",
                fontWeight: "bold",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#FFC107",
                },
                marginBottom: "4px",
              }}
              label="Write a Review"
            />
          </div>
        )}
      </div>

      {value === 0 ? (
        <>
          <div className="row">
            <div className="col-lg-12">
              <div
                className="text-secondary mt-3"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            </div>
          </div>
        </>
      ) : (
        <>
          {val === 1 ? (
            <>
              <FaArrowLeft
                onClick={() => setVal(0)}
                style={{ cursor: "pointer", color: "white" }}
              />
              <AddReview setData={setVal} />
            </>
          ) : val === 2 ? (
            <>
              <FaArrowLeft
                onClick={() => setVal(0)}
                style={{ cursor: "pointer", color: "white" }}
              />
              <EditReview setData={setVal} />
            </>
          ) : (
            <ReviewSection setData={setVal} />
          )}
        </>
      )}
    </Box>
  );
}
