import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Pagination,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TfiShoppingCart } from "react-icons/tfi";
import { GiRoundStar } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { picBaseUrl } from "../../config/config";
import { useScroll } from "../../hooks/AppContext";
import { AllTrendingProducts } from "../../DAL/TrendingProducts/Trending";

export default function TrendingProducts() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { NoToken, handleScrollToTop } = useScroll();
  const [loading, setLoading] = useState(false);

  const GetTrendingProduct = async () => {
    setLoading(true);
    const resp = await AllTrendingProducts();
    if (resp?.status == true) {
      setData(resp?.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    NoToken();
    GetTrendingProduct();
  }, []);
  return (
    <>
      <div className="mainDiv">
        <Typography
          variant="h5"
          className="text-white"
          sx={{
            paddingTop: { lg: "1.5rem", xs: "10px" },
            marginLeft: { lg: "37px", md: "36px", sm: "25px", xs: "20px" },
            marginBottom: { lg: "20px" },
          }}
        >
          Trending Products
        </Typography>

        {loading == false && data?.length == 0 && (
          <h3 className="text-center text-white mt-5 mb-5">Data not found</h3>
        )}
        <div style={{ width: "96%", margin: "auto" }}>
          <Grid container spacing={2}>
            {loading == false && data?.length == 0 && (
              <h3 className="text-center text-white mt-5 mb-5">
                Data not found
              </h3>
            )}
            {/* add skeleton loader */}
            {loading == true &&
              Array.from({ length: 20 }).map((val) => (
                <>
                  <Grid item lg={2} md={3} sm={6} xs={6}>
                    <Skeleton
                      sx={{ height: 340, width: "100%", borderRadius: "20px" }}
                      animation="wave"
                      variant="rectangular"
                    />
                  </Grid>
                </>
              ))}
            {/* show api data */}
            {loading == false &&
              data?.map((val) => (
                <>
                  <Grid item lg={2} md={3} sm={6} xs={6}>
                    <Card
                      className="card favouriteCards position-relative"
                      sx={{
                        padding: {
                          lg: "10px 10px 0px 10px",
                          md: "10px 10px 0px 10px",
                          sm: "5px 5px 0px 5px",
                          xs: "6px 6px 0px 6px",
                        },
                        height: {
                          lg: "330px",
                          md: "330px",
                          sm: "330px",
                          xs: "300px",
                        },
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(`/product-detail/${val?.id}`);
                        handleScrollToTop();
                      }}
                    >
                      {val?.discount == 0 ? (
                        ""
                      ) : (
                        <button className="discountBtn">
                          {val?.discount + "%"}
                        </button>
                      )}

                      <CardMedia
                        sx={{
                          height: "150px",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                        image={picBaseUrl + val?.thumbnail}
                        alt=""
                      />
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          className="text-white"
                          sx={{
                            whiteSpace: "normal",
                            wordWrap: "breakWord",
                            fontSize: {
                              lg: "1rem",
                              md: "0.8rem",
                              sm: "0.76rem",
                              xs: "0.76rem",
                            },
                          }}
                        >
                          {val?.name?.length > 30
                            ? val?.name.substring(0, 30) + "..."
                            : val?.name}
                        </Typography>

                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          className="text-white"
                          sx={{
                            whiteSpace: "normal",
                            wordWrap: "breakWord",
                            fontSize: {
                              lg: "1rem",
                              md: "0.8rem",
                              sm: "0.76rem",
                              xs: "0.76rem",
                            },
                          }}
                        >
                          RS: {val?.price}
                        </Typography>
                        <div
                          className="d-flex justify-content-between align-items-center mt-3"
                          style={{
                            position: "absolute",
                            bottom: "20px",
                            left: "20px",
                            right: "10px",
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <GiRoundStar
                              sx={{
                                whiteSpace: "normal",
                                wordWrap: "breakWord",
                                fontSize: {
                                  lg: "1rem",
                                  md: "0.8rem",
                                  sm: "0.76rem",
                                  xs: "0.76rem",
                                },
                              }}
                              color="gold"
                            />
                            <span
                              className="mx-2 mt-1 text-secondary"
                              sx={{
                                whiteSpace: "normal",
                                wordWrap: "breakWord",
                                fontSize: {
                                  lg: "1rem",
                                  md: "0.8rem",
                                  sm: "0.76rem",
                                  xs: "0.76rem",
                                },
                              }}
                            >
                              {Number(val?.rating)?.toFixed(1)}{" "}
                              <span>({val?.reviews_count})</span>
                            </span>
                          </div>
                          <TfiShoppingCart
                            style={{ fontSize: "31px", marginTop: "10px" }}
                            color="gold"
                          />
                        </div>
                      </CardContent>
                    </Card>
                    {/* </div> */}
                  </Grid>
                </>
              ))}
          </Grid>
        </div>
      </div>
    </>
  );
}
