import React, { useEffect } from "react";
import icon from "../../assets/images/Icon.svg";
import union from "../../assets/images/Union.png";
import image1 from "../../assets/images/image 1 about.webp";
import image2 from "../../assets/images/image 2 about.webp";
import image3 from "../../assets/images/image 3 about.webp";
import image4 from "../../assets/images/image 4 about.webp";

const About = () => {
  useEffect(() => {
    document.title = "About Us";
  }, []);
  return (
    <>
      <div className=" howitworks-con mainDiv">
        <div className="container text-white">
          <div className="row pt-5 ">
            <div className="col-lg-6 animate ">
              <img src={icon} alt="" className="pb-2" />
              <span className="h4 mx-3">About Us</span>
              <h1>Our Company Overview</h1>
              <p className="text-secondary">
                Bells future-proof land picture view. Knowledge sorry sexy hits
                stronger ideal crack items savvy. All manage a keywords language
                diarize I. If donuts lift player-coach managing field harvest
                pain crank. Like bed take cross these we product plane agile
                investigation.
              </p>
              <hr />
              <p className="text-secondary">
                Moments sandwich knowledge thought angel offline. Launch good
                hear it's let canatics. Don't alarming company latest metal
                enable t-shaped join enable innovation. Dangerous while put
                place organic teams we native race already. Uat crack better i'm
                alpha calculator box needle our. Helicopter dear gmail into
                community. Q1 ensure eow closing savvy closest problem.
              </p>
            </div>
            <div className="col-lg-6 animate-image  text-center align-item-center mb-5">
              <img src={image1} alt="" style={{ width: "80%" }} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container text-white mt-5">
          <div className="row">
            <div className="col-lg-4">
              <h3 className="text-warning">Our Mission</h3>
              <p>
                Bells future-proof land picture view. Knowledge sorry sexy hits
                stronger ideal crack items savvy. All manage a keywords language
                diarize I. If donuts lift player-coach managing field harvest
                pain crank. Like bed take cross these we product plane agile
                investigation.
              </p>
            </div>
            <div
              className="col-lg-4 hr-design mx-auto"
              style={{ width: "10%" }}
            ></div>
            <div className="col-lg-4 ">
              <h3 className="text-warning">Our Goals</h3>
              <p>
                Moments sandwich knowledge thought angel offline. Launch good
                hear it's let canatics. Don't alarming company latest metal
                enable t-shaped join enable innovation. Dangerous while put
                place organic teams we native race already.{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="container pb-5">
          <div className=" d-flex justify-content-center mb-5 mt-5">
            <img src={union} alt="" height={40} />
            <h2 className=" text-warning mx-3 mt-2">Our Values</h2>
          </div>
          <div className="row text-center">
            <div className="col-lg-3 col-md-6 d-flex justify-content-center">
              <div className="card-about-us flip-box">
                <div className="flip-box-inner">
                  <div className="flip-box-front">
                    <img src={image1} alt="" className="img opacity-25" />
                    <div class="centered-about-us">CentLorem ipsum dolor sit amet consectetur adipisicing elit. Ea, officiis?ered</div>
                  </div>
                  <div className="flip-box-back ">
                  <div className="centered">
                    <h5 class="card-title">Make it Personal</h5>
                    <p className="card-text">
                      Need without ditching finance looking solutionize must
                      low-hanging. Horse guys out horse without highlights
                      shift. Leverage money wanted if big guys. Knowledge stands
                      culture follow ditching one. Bed pants nail first-order
                      respectively die idea make cc game.
                    </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 d-flex justify-content-center">
              <div className="card-about-us flip-box">
                <div className="flip-box-inner">
                  <div className="flip-box-front">
                    <img src={image2} alt="" className="img opacity-25" />
                    <div class="centered-about-us">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, officiis?</div>
                  </div>
                  <div className="flip-box-back">
                  <div className="centered">
                    <h5 class="card-title">Make it Personal</h5>
                    <p className="card-text">
                      Need without ditching finance looking solutionize must
                      low-hanging. Horse guys out horse without highlights
                      shift. Leverage money wanted if big guys. Knowledge stands
                      culture follow ditching one. Bed pants nail first-order
                      respectively die idea make cc game.
                    </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 d-flex justify-content-center">
              <div className="card-about-us flip-box">
                <div className="flip-box-inner">
                  <div className="flip-box-front">
                    <img src={image3} alt="" className="img opacity-25" />
                    <div class="centered-about-us">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, officiis?</div>
                  </div>
                  <div className="flip-box-back">
                  <div className="centered">
                    <h5 class="card-title">Make it Personal</h5>
                    <p className="card-text">
                      Need without ditching finance looking solutionize must
                      low-hanging. Horse guys out horse without highlights
                      shift. Leverage money wanted if big guys. Knowledge stands
                      culture follow ditching one. Bed pants nail first-order
                      respectively die idea make cc game.
                    </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 d-flex justify-content-center">
              <div className=" card-about-us flip-box">
                <div className="flip-box-inner">
                  <div className="flip-box-front">
                    <img src={image4} alt="" className="img opacity-25" />
                    <div class="centered-about-us">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, officiis?</div>
                  </div>
                  <div className="flip-box-back ">
                  <div className="centered">
                    <h5 class="card-title">Make it Personal</h5>
                    <p className="card-text">
                      Need without ditching finance looking solutionize must
                      low-hanging. Horse guys out horse without highlights
                      shift. Leverage money wanted if big guys. Knowledge stands
                      culture follow ditching one. Bed pants nail first-order
                      respectively die idea make cc game.
                    </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
