import {
  Button,
  Card,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import payment from "../../assets/images/payement method.svg";
import React, { useEffect, useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function ShippingMethod({ setActive, amount }) {
  const navigate = useNavigate();
  const [advancePayment, setAdvancePayment] = useState();
  const [selectedOption, setSelectedOption] = useState("advance");
  const [remaining, setRemaining] = useState();
  const [updatedPercentValue, setUpdatedPercentValue] = useState([]);
  const [total, setTotal] = useState("");
  const [cartData, setCartData] = useState([]);

  const Calculation = (option) => {
    if (option === "advance") {
      const value = (total / 100) * 20;
      setAdvancePayment(value);
      const remain = total - value;
      setRemaining(remain);
      const updatedCartItems = cartData?.map((item) => {
        const advancePayment = (item?.products?.price / 100) * 20;
        const remainingAmount = item?.products?.price - advancePayment;

        return {
          ...item,
          products: {
            ...item.products,
            advancePayment,
            remainingAmount,
          },
        };
      });
      setUpdatedPercentValue(updatedCartItems);
    } else {
      setAdvancePayment(total);
      setRemaining(0);
    }
  };

  const handleCardClick = (option) => {
    setSelectedOption(option);
    Calculation(option);
  };

  useEffect(() => {
    const totalAmount = localStorage.getItem("totalAmount");
    const cartItems = JSON.parse(localStorage.getItem("cartItems"));
    setCartData(cartItems);
    setTotal(totalAmount);
  }, []);

  useEffect(() => {
    if (total && cartData) {
      Calculation("advance");
    }
  }, [total, cartData]);
  return (
    <>
      <div
        style={{
          backgroundColor: "#000000",
          padding: "30px",
          marginTop: "2rem",
          borderRadius: "10px",
        }}
      >
        <div style={{ width: "90%", margin: "auto" }}>
          <Typography className="d-flex gap-1 align-items-center">
            <span>
              <img
                src={payment}
                alt="Payment"
                style={{ color: "white", height: "25px", width: "25px" }}
              />
            </span>
            <span className="fw-bold text-warning">Payment Method</span>
          </Typography>

          <div className="row mt-4">
            <div className="col-lg-6">
              <Card
                sx={{
                  backgroundColor: "#2C2C2C",
                  padding: "5px 0px 30px 5px",
                  cursor: "pointer",
                  border:
                    selectedOption === "advance"
                      ? "1px solid  #ffbf00"
                      : "none",
                }}
                onClick={() => handleCardClick("advance")}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor:
                      selectedOption == "advance" ? "#2C2C2C" : "white",
                    textAlign: "center",
                  }}
                >
                  {selectedOption == "advance" ? (
                    <FaRegCheckCircle
                      style={{
                        width: "20px",
                        height: "20px",
                        marginBottom: "6px",
                        color: "#ffbf00",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>

                <Typography
                  variant="h6"
                  className={
                    selectedOption === "advance"
                      ? "fw-bold text-white text-center"
                      : "fw-bold text-secondary text-center"
                  }
                >
                  Pay 20% Advance
                </Typography>
              </Card>
            </div>
            <div className="col-lg-6 mobile-view">
              <Card
                sx={{
                  backgroundColor: "#2C2C2C",
                  padding: "5px 0px 30px 5px",
                  cursor: "pointer",
                  border:
                    selectedOption === "full" ? "1px solid  #ffbf00" : "none",
                }}
                onClick={() => handleCardClick("full")}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor:
                      selectedOption == "full" ? "#2C2C2C" : "white",
                    textAlign: "center",
                  }}
                >
                  {selectedOption == "full" ? (
                    <FaRegCheckCircle
                      style={{
                        width: "20px",
                        height: "20px",
                        marginBottom: "6px",
                        color: "#ffbf00",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>

                <Typography
                  variant="h6"
                  className={
                    selectedOption === "full"
                      ? "fw-bold text-white text-center"
                      : "fw-bold text-secondary text-center"
                  }
                >
                  Pay Full Payment Advance
                </Typography>
              </Card>
            </div>
          </div>
          <div className="text-end mt-3">
            <Button
              variant="outlined"
              className="outline-btn fw-bold"
              sx={{
                color: "#ffc107",
                borderColor: "#ffc107",
                textTransform: "none",
              }}
              onClick={() => {
                if (selectedOption == null) {
                  enqueueSnackbar("Please select shipping method", {
                    variant: "error",
                  });
                  return;
                }
                setActive(2); // Set active state

                navigate("", {
                  state: {
                    amount,
                    remaining,
                    advancePayment,
                    updatedPercentValue,
                  },
                });
                localStorage.setItem(
                  "updatedValue",
                  JSON.stringify(updatedPercentValue)
                );
              }}
            >
              Pay Now
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
