import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Pagination,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TfiShoppingCart } from "react-icons/tfi";
import { GiRoundStar } from "react-icons/gi";
import { useLocation, useNavigate } from "react-router-dom";
import { picBaseUrl } from "../../config/config";
import { useScroll } from "../../hooks/AppContext";

export default function StoreAllProducts() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const { handleScrollToTop } = useScroll();

  useEffect(() => {
    setData(state?.products);
  }, []);
  return (
    <>
      <div className="mt-4">
        <div style={{ width: "96%", margin: "auto" }}>
          <Typography
            variant="h5"
            className="text-white "
            sx={{
              marginLeft: "10px",
              marginBottom: { lg: "20px" },
            }}
          >
            {state?.shop_name}
          </Typography>

          <Grid container spacing={2}>
            {data?.map((val) => (
              <>
                <Grid item lg={2} md={3} sm={6} xs={6}>
                  <Card
                    className="card favouriteCards position-relative"
                    sx={{
                      padding: {
                        lg: "10px 10px 0px 10px",
                        md: "10px 10px 0px 10px",
                        sm: "5px 5px 0px 5px",
                        xs: "6px 6px 0px 6px",
                      },
                      height: {
                        lg: "330px",
                        md: "330px",
                        sm: "330px",
                        xs: "300px",
                      },
                      width: "100%",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(`/product-detail/${val?.id}`)}
                  >
                    {val?.discount == 0 ? (
                      ""
                    ) : (
                      <button className="discountBtn">
                        {val?.discount + "%"}
                      </button>
                    )}

                    <CardMedia
                      sx={{
                        height: "150px",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                      image={picBaseUrl + val?.thumbnail}
                      alt=""
                    />
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        className="text-white"
                        sx={{
                          whiteSpace: "normal",
                          wordWrap: "breakWord",
                          fontSize: {
                            lg: "1rem",
                            md: "0.8rem",
                            sm: "0.76rem",
                            xs: "0.76rem",
                          },
                        }}
                      >
                        {val?.name?.length > 30
                          ? val?.name.substring(0, 30) + "..."
                          : val?.name}
                      </Typography>

                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        className="text-white"
                        sx={{
                          whiteSpace: "normal",
                          wordWrap: "breakWord",
                          fontSize: {
                            lg: "1rem",
                            md: "0.8rem",
                            sm: "0.76rem",
                            xs: "0.76rem",
                          },
                        }}
                      >
                        RS: {val?.price}
                      </Typography>
                      <div
                        className="d-flex justify-content-between align-items-center mt-3"
                        style={{
                          position: "absolute",
                          bottom: "20px",
                          left: "20px",
                          right: "10px",
                        }}
                      >
                        <div className="d-flex ">
                          <GiRoundStar
                            sx={{
                              whiteSpace: "normal",
                              wordWrap: "breakWord",
                              fontSize: {
                                lg: "1rem",
                                md: "0.8rem",
                                sm: "0.76rem",
                                xs: "0.76rem",
                              },
                            }}
                            color="gold"
                          />
                          <span
                            className="mx-2  text-secondary"
                            sx={{
                              whiteSpace: "normal",
                              wordWrap: "breakWord",
                              fontSize: {
                                lg: "1rem",
                                md: "0.8rem",
                                sm: "0.76rem",
                                xs: "0.76rem",
                              },
                            }}
                          >
                            {Number(val?.rating)?.toFixed(1)}{" "}
                            <span>({val?.reviews_count})</span>
                          </span>
                        </div>
                        <TfiShoppingCart
                          style={{ fontSize: "31px", marginTop: "10px" }}
                          color="gold"
                        />
                      </div>
                    </CardContent>
                  </Card>
                  {/* </div> */}
                </Grid>
              </>
            ))}
          </Grid>
        </div>
      </div>
    </>
  );
}
