import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Pagination,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TfiShoppingCart } from "react-icons/tfi";
import { GiRoundStar } from "react-icons/gi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CategoryProduct } from "../../DAL/Categories/Category";
import { picBaseUrl } from "../../config/config";
import { useScroll } from "../../hooks/AppContext";

export default function CategoryProductsList() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const { NoToken, handleScrollToTop } = useScroll();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const GetCategoryProduct = async () => {
    setLoading(true);
    const resp = await CategoryProduct(id, page);
    if (resp?.status == true) {
      setData(resp?.data?.data);
      setTotalPages(Math.ceil(resp?.data?.total / resp?.data?.per_page));
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    handleScrollToTop();
    setPage(value);
  };

  useEffect(() => {
    NoToken();
    GetCategoryProduct();
  }, [page]);
  return (
    <>
      <div className="mt-3">
        <div className="text-start mx-5 mb-4">
          <Typography variant="h5" className="text-white">
            {state.length > 20 ? state.substring(0, 25) + "..." : state}
          </Typography>
        </div>
        <Box
          sx={{
            width: { lg: "95%", md: "97%", sm: "98%", xs: "98%" },
            margin: "auto",
          }}
        >
          {loading == false && data?.length == 0 && (
            <h4 className="text-center text-white mt-5 mb-5">
              No Products Available
            </h4>
          )}
          <Grid container spacing={2}>
            {loading == true &&
              Array.from({ length: 20 }).map((val) => (
                <>
                  <Grid item lg={2} md={3} sm={6} xs={6}>
                    <Skeleton
                      sx={{ height: 340, width: "100%", borderRadius: "20px" }}
                      animation="wave"
                      variant="rectangular"
                    />
                  </Grid>
                </>
              ))}

            {loading == false &&
              data?.map((val) => (
                <>
                  <Grid item lg={2} md={3} sm={6} xs={6}>
                    <Card
                      className="card favouriteCards position-relative"
                      sx={{
                        padding: {
                          lg: "10px 10px 0px 10px",
                          md: "10px 10px 0px 10px",
                          sm: "5px 5px 0px 5px",
                          xs: "6px 6px 0px 6px",
                        },
                        height: {
                          lg: "330px",
                          md: "330px",
                          sm: "330px",
                          xs: "300px",
                        },
                        width: "100%",
                      }}
                      onClick={() => navigate(`/product-detail/${val?.id}`)}
                    >
                      {val?.discount == 0 ? (
                        ""
                      ) : (
                        <button className="discountBtn">
                          {val?.discount + "%"}
                        </button>
                      )}

                      <CardMedia
                        sx={{
                          height: "150px",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                        image={picBaseUrl + val?.thumbnail}
                        alt=""
                      />
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          className="text-white"
                          sx={{
                            whiteSpace: "normal",
                            wordWrap: "breakWord",
                            fontSize: {
                              lg: "1rem",
                              md: "0.8rem",
                              sm: "0.76rem",
                              xs: "0.76rem",
                            },
                          }}
                        >
                          {val?.name.length > 30
                            ? val?.name.substring(0, 30) + "..."
                            : val?.name}
                        </Typography>

                        <Typography
                          gutterBottom
                          variant="p"
                          component="div"
                          className="text-white"
                          sx={{
                            whiteSpace: "normal",
                            wordWrap: "breakWord",
                            fontSize: {
                              lg: "1rem",
                              md: "0.8rem",
                              sm: "0.76rem",
                              xs: "0.76rem",
                            },
                          }}
                        >
                          Rs. {val?.price}
                        </Typography>
                        <div className="d-flex justify-content-between align-items-center mt-3">
                          <div className="d-flex align-items-center">
                            <GiRoundStar
                              sx={{
                                whiteSpace: "normal",
                                wordWrap: "breakWord",
                                fontSize: {
                                  lg: "1.3rem",
                                  md: "1rem",
                                  sm: "0.76rem",
                                  xs: "0.76rem",
                                },
                              }}
                              color="gold"
                            />
                            <span
                              className="mx-2 mt-1 text-secondary"
                              sx={{
                                fontSize: {
                                  lg: "1rem",
                                  md: "0.8rem",
                                  sm: "0.76rem",
                                  xs: "0.76rem",
                                },
                              }}
                            >
                              {Number(val?.rating)?.toFixed(1)}{" "}
                              <span>({val?.reviews_count})</span>
                            </span>
                          </div>
                          <TfiShoppingCart
                            style={{ fontSize: "31px", marginTop: "10px" }}
                            color="gold"
                          />
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              ))}
          </Grid>
        </Box>
        {data?.length > 0 && (
          <Pagination
            count={totalPages}
            page={page}
            siblingCount={0}
            onChange={handlePageChange}
            sx={{
              mt: 4,
              display: "flex",
              justifyContent: "center",
              "& .MuiPaginationItem-root": {
                color: "white",
                borderColor: "gold",
              },
              "& .MuiPaginationItem-root.Mui-selected": {
                backgroundColor: "gold",
                color: "black",
              },
              "& .MuiPaginationItem-root:hover": {
                backgroundColor: "gold",
                color: "black",
              },
            }}
            shape="rounded"
          />
        )}
      </div>
    </>
  );
}
