import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { picBaseUrl } from "../config/config";

export default function Message({ message }) {
  console.log(message, "kldsjfkjsdljfjkld");
  return (
    <div>
      <div className="d-flex gap-1 align-items-center mb-2">
        <Avatar src="logo192.png" sx={{ width: 22, height: 22 }} />
        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
          GoldBazaar
        </Typography>
      </div>
      <Box>
        {JSON.parse(message?.body)?.image && (
          <img
            src={picBaseUrl + JSON.parse(message?.body)?.image}
            style={{ width: "100%", height: "110px", borderRadius: "3px" }}
          />
        )}
        <Box
          sx={{ whiteSpace: "normal", wordWrap: "breakWord" }}
          className="g-0 mt-2"
        >
          <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
            {message?.title}
          </Typography>
          <Typography sx={{ fontSize: "11px" }}>
            {JSON.parse(message?.body)?.content}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}
