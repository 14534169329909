import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { GiRoundStar } from "react-icons/gi";
import { TfiShoppingCart } from "react-icons/tfi";
import { Box, Grid, Pagination, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { picBaseUrl } from "../../config/config";
import { GetSellerAllItems } from "../../DAL/SellerShop/SellerShop";
import { useScroll } from "../../hooks/AppContext";

export default function AllItems({ shopId }) {
  const navigate = useNavigate();
  const [allItems, setAllItems] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const { handleScrollToTop } = useScroll();
  const [totalPages, setTotalPages] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const handleChange = (e, newValue) => {
    setPage(newValue);
  };
  const GetAllItemsData = async () => {
    setLoading(true);
    const resp = await GetSellerAllItems(shopId, page);
    if (resp.status == true) {
      setAllItems(resp?.data?.data);
      setTotalPages(Math.ceil(resp?.data?.total / resp?.data?.per_page));
      setLoading(false);
    }
  };

  React.useEffect(() => {
    GetAllItemsData();
  }, [page]);
  return (
    <>
      <Grid container spacing={2}>
        {loading == true &&
          Array.from({ length: 20 }).map(() => (
            <>
              <Grid item lg={2} md={3} sm={6} xs={6} sx={{ marginTop: "30px" }}>
                <Skeleton
                  sx={{
                    height: { lg: 320, sm: 280, xs: 280 },
                    width: "100%",
                    borderRadius: "20px",
                    marginTop: "2rem",
                  }}
                  animation="wave"
                  variant="rectangular"
                />
              </Grid>
            </>
          ))}
        {loading == false &&
          allItems?.length > 0 &&
          allItems?.map((val) => (
            <>
              <Grid item lg={2} md={3} sm={6} xs={6} sx={{ marginTop: "30px" }}>
                <Card
                  className="card favouriteCards position-relative"
                  sx={{
                    padding: {
                      lg: "10px 10px 0px 10px",
                      md: "10px 10px 0px 10px",
                      sm: "5px 5px 0px 5px",
                      xs: "6px 6px 0px 6px",
                    },
                    height: {
                      lg: "330px",
                      md: "330px",
                      sm: "330px",
                      xs: "300px",
                    },
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/product-detail/${val?.id}`);
                    handleScrollToTop();
                  }}
                >
                  {val?.discount == 0 ? (
                    ""
                  ) : (
                    <button className="discountBtn">
                      {val?.discount + "%"}
                    </button>
                  )}

                  <CardMedia
                    sx={{
                      height: "150px",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    image={picBaseUrl + val?.thumbnail}
                    alt=""
                  />
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      className="text-white"
                      sx={{
                        whiteSpace: "normal",
                        wordWrap: "breakWord",
                        fontSize: {
                          lg: "1rem",
                          md: "0.8rem",
                          sm: "0.76rem",
                          xs: "0.76rem",
                        },
                      }}
                    >
                      {val?.name?.length > 30
                        ? val?.name.substring(0, 30) + "..."
                        : val?.name}
                    </Typography>

                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      className="text-white"
                      sx={{
                        whiteSpace: "normal",
                        wordWrap: "breakWord",
                        fontSize: {
                          lg: "1rem",
                          md: "0.8rem",
                          sm: "0.76rem",
                          xs: "0.76rem",
                        },
                      }}
                    >
                      RS: {val?.price}
                    </Typography>
                    <div
                      className="d-flex justify-content-between align-items-center mt-3"
                      style={{
                        position: "absolute",
                        bottom: "20px",
                        left: "20px",
                        right: "10px",
                      }}
                    >
                      <div className="d-flex ">
                        <GiRoundStar
                          sx={{
                            whiteSpace: "normal",
                            wordWrap: "breakWord",
                            fontSize: {
                              lg: "1rem",
                              md: "0.8rem",
                              sm: "0.76rem",
                              xs: "0.76rem",
                            },
                          }}
                          color="gold"
                        />
                        <span
                          className="mx-2  text-secondary"
                          sx={{
                            whiteSpace: "normal",
                            wordWrap: "breakWord",
                            fontSize: {
                              lg: "1rem",
                              md: "0.8rem",
                              sm: "0.76rem",
                              xs: "0.76rem",
                            },
                          }}
                        >
                          {Number(val?.rating)?.toFixed(1)}{" "}
                          <span>({val?.reviews_count})</span>
                        </span>
                      </div>
                      <TfiShoppingCart
                        style={{ fontSize: "31px", marginTop: "10px" }}
                        color="gold"
                      />
                    </div>
                  </CardContent>
                </Card>
                {/* </div> */}
              </Grid>
            </>
          ))}
      </Grid>
      <div className="d-flex justify-content-center mt-5">
        <Pagination
          count={totalPages}
          shape="rounded"
          page={page}
          siblingCount={0}
          onChange={handleChange}
          rowsPerPage={5}
          sx={{
            "& .MuiPaginationItem-root": {
              color: "white",
              borderColor: "gold",
            },
            "& .MuiPaginationItem-root.Mui-selected": {
              backgroundColor: "gold",
              color: "black",
            },
            "& .MuiPaginationItem-root:hover": {
              backgroundColor: "gold",
              color: "black",
            },
          }}
        />
      </div>
    </>
  );
}
