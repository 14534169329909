import React from "react";
import Becomeasupplier from "./Pages/BecomeSupplier/Becomeasupplier";
import Blog from "./Pages/Blog/Blog";
import Reviews from "./Pages/Careers/Reviews";
import { Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout";
import About from "./Pages/About/About";
import BlogDetails from "./Pages/Blog/BolgDetails";
import Howitworks from "./Pages/Howitworks/Howitworks";
import Privacy from "./Pages/Policy/Privacy";
import Refund from "./Pages/Policy/Refund";
import Shipping from "./Pages/Policy/Shipping";
import Termsofuse from "./Pages/Terms of use/Termsofuse";
import Faq from "./Pages/FAQ/Faq";
import CareersDetails from "./Pages/Careers/ReviewsDetails";
import Cards from "./Pages/Howitworks/Cards";
import CategoriesListing from "./Pages/AllCategoriesList/CategoriesListing";
import CategoryProductsList from "./Pages/AllCategoriesList/categoryProductsList";
import DetailSection from "./Pages/ProductDetailSection/DetailSection";
import RingSizeActivity from "./Pages/RingSizeActivity/RingSizeActivity";
import GoldRates from "./Pages/Rates/GoldRates";
import SellerShop from "./Pages/SellerShop/SellerShop";
import Cart from "./Pages/Cart/Cart";
import Favorites from "./Pages/Favorites/Favorites";
import FollowedShops from "./Pages/Followed Shops/FollowedShops";
import Login from "./Pages/Login/Login";
import Signup from "./Pages/Signup/Signup";
import PreLogin from "./Pages/Login/PreLogin";
import Profile from "./Pages/Profile/Profile";
import OTPInput from "./Pages/Profile/Otp";
import ProfileEdit from "./Pages/Profile/ProfileEdit";
// import AllTrendings from "./Pages/Howitworks/AllTrendings";
import ForgetPassword from "./Pages/ForgetPassword/ForgetPassword";
import OtpVerification from "./Pages/ForgetPassword/OtpVerification";
import NewPassword from "./Pages/ForgetPassword/NewPassword";
import MyOrder from "./Pages/MyOrders/MyOrder";
import ReturnProduct from "./Pages/MyOrders/ReturnProduct";
import PaymentStepper from "./Pages/PaymentProcess/Index";
import SearchResult from "./Pages/Search/Search";
import Coming from "./Pages/Coming/Coming";
import TrendingProducts from "./Pages/AllTrendingProducts/AllProducts";
import StoreAllProducts, {
  StoreProducts,
} from "./Pages/StoreAllProducts/StoreProducts";
import Notification from "./Pages/Navbar/Notification";

const RoutesFile = () => {
  return (
    <>
      <div className="main ">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Howitworks />} />

            <Route path="/profile" element={<Profile />} />
            <Route path="/profile-edit" element={<ProfileEdit />} />
            <Route path="/return-product" element={<ReturnProduct />} />
            <Route path="/about" element={<About />} />
            <Route path="/" element={<Howitworks />} />
            <Route path="/become-a-supplier" element={<Becomeasupplier />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/blogs" element={<Blog />} />
            <Route path="/user-insights" element={<Reviews />} />
            <Route path="/blog-detail/:id" element={<BlogDetails />} />
            <Route path="/careers-details" element={<CareersDetails />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/shipping" element={<Shipping />} />
            <Route path="/terms" element={<Termsofuse />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/cards" element={<Cards />} />
            <Route path="/all-products" element={<StoreAllProducts />} />
            <Route path="/trending-products" element={<TrendingProducts />} />
            <Route path="/coming" element={<Coming />} />
            <Route path="/search-result" element={<SearchResult />} />
            <Route path="/categories-listing" element={<CategoriesListing />} />
            <Route
              path="/category-products/:id"
              element={<CategoryProductsList />}
            />

            <Route path="/product-detail/:id" element={<DetailSection />} />
            <Route path="/cards" element={<Cards />} />
            <Route path="/RingSizeActivity" element={<RingSizeActivity />} />
            <Route path="/gold-rate" element={<GoldRates />} />
            <Route path="/seller-shop/:id" element={<SellerShop />} />
            <Route path="/cart" element={<Cart />} />

            <Route path="/favorites" element={<Favorites />} />
            <Route path="/followed-shops" element={<FollowedShops />} />
            <Route path="/my-order" element={<MyOrder />} />
            <Route path="/payment-process" element={<PaymentStepper />} />
          </Route>
          <Route path="/pre-login" element={<PreLogin />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/otp-verfication" element={<OtpVerification />} />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<Signup />} />
          <Route path="/otp" element={<OTPInput />} />
        </Routes>
      </div>
    </>
  );
};

export default RoutesFile;
