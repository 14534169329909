import React, { useEffect, useState } from "react";

import test from "../../assets/video/test.mp4";

import mobile from "../../assets/images/mobile.webp";
import qr from "../../assets/images/qr.webp";
import button from "../../assets/images/button.webp";
import apple from "../../assets/images/apple.png";
import Cards from "./Cards";
import HeroSectionSlider from "./heroSliderSection";
import CategorySlider from "./CategorySlider";
import TrendingSlider from "./TrendingSlider";
import DubaiGold from "./FirstStoreProducts";
import FeatureSection from "./featureSection";
import { BannerList } from "../../DAL/Banners/Banner";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import SecondStore from "./StoreTopProducts";
import { useTranslation } from "react-i18next";

const Howitworks = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [bannerData, setBannerData] = useState(
    JSON.parse(localStorage.getItem("banner_data")) || []
  );

  const GetBannerList = async () => {
    const resp = await BannerList();
    if (resp?.status === true) {
      localStorage.setItem("banner_data", JSON.stringify(resp?.data));
      return resp?.data;
    }
    throw new Error("Error fetching banners");
  };

  const { isLoading, isError, error } = useQuery("bannerList", GetBannerList, {
    initialData: bannerData,
    staleTime: 0,
    onSuccess: (newData) => {
      setBannerData(newData);
    },
  });

  if (isError) {
    return enqueueSnackbar(error.message, { variant: "error" });
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    document.title = "homepage";
  }, []);

  return (
    <>
      <div className="mainDiv">
        {isLoading ? (
          <>
            <div className="">
              <Skeleton
                sx={{ height: 230, width: 375, borderRadius: "20px" }}
                animation="wave"
                variant="rectangular"
              />
            </div>
          </>
        ) : (
          <HeroSectionSlider dataArray={bannerData} />
        )}

        {/* categories section */}

        <div className="mx-3 mt-4">
          <CategorySlider />
        </div>

        {/* Tending section */}

        <div className="mx-3 mt-5">
          <TrendingSlider />
        </div>

        {/* heading text section */}

        <div
          className="text-center p-5 mt-5"
          style={{ backgroundColor: "black" }}
        >
          <h5 className="text-white mb-4">{t("welcome")}</h5>
          <div>
            <p className="text-white text-center change-content">
              {t("content")}
            </p>
          </div>
        </div>

        {/* gold store section */}
        <div className="mx-3 mt-4">
          <DubaiGold />
        </div>
        {/* second store top products */}
        <div className="mx-3 mt-4">
          <SecondStore />
        </div>

        {/* -----------------------------Features Section -------------------------------------*/}

        <FeatureSection />
        {/*------------------------------------------ Video Section ---------------------------*/}
        <div className="text-center container text-white mt-5">
          <video
            className="video"
            src={test}
            muted
            autoPlay
            controls
            loop
          ></video>
        </div>

        {/* ------------------------------Download App --------------------------------------- */}
        <section>
          <div className="container text-center">
            <div className="row download-css">
              <div className="col-lg-1"></div>
              <div className="col-lg-6 mt-5">
                <h3 className="text-warning">Download this App</h3>
                <p className="text-secondary mt-3">
                  Ready to embark on your gold shopping journey? Download the
                  Gold Bazaar app today from the App Store or Google Play. Join
                  our community of gold enthusiasts and enjoy a shopping
                  experience that’s as precious as gold itself.
                </p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={qr} alt="" width={120} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      marginLeft: "15px",
                    }}
                  >
                    <img src={button} alt="" width={200} />
                    <img src={apple} alt="" width={200} />
                  </div>
                </div>
              </div>
              <div className="col-lg-5 mobile-mobile-view">
                {/* d-flex jusifty-content-start */}
                <img
                  src={mobile}
                  alt=""
                  style={{ width: "67%", height: "auto", top: "0%" }}
                />
              </div>
            </div>
          </div>
        </section>
        <Cards />
      </div>
    </>
  );
};

export default Howitworks;
